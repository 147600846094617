import React from "react";
import { MODE } from "../../../constants/common";
import AddEditBundle from "./AddEditBundle/AddEditBundle";
import BundleList from "./BundleList/BundleList";

function Bundle(props) {
  const {
    id,
    distributorId,
    catalogId,
    mode,
    onSave,
    onCancel,
    onEdit,
    onNext,
    query,
    onView,
    showColumnPopup,
    onQueryChange,
    signUpApproval,
    permissions,
    setContentId,
    setContentMode,
    setBundleId,
    setBundleMode,
    setCatalogId,
    setCatalogMode,
    onViewHandlerList,
    onEditHandlerList,
  } = props;

  return (
    <div>
      {mode === MODE.LIST ? (
        <BundleList
          id={id}
          query={query}
          showColumnPopup={showColumnPopup}
          onCancelTableColumnSelection={props.onCancelTableColumnSelection}
          onView={onView}
          onEdit={onEdit}
          setQuery={onQueryChange}
          signUpApproval={signUpApproval}
          permissions={permissions}
        />
      ) : (
        <AddEditBundle
          id={id}
          query={query}
          distributorId={distributorId}
          bundleId={catalogId}
          setSelectedSubTab={props.setSelectedSubTab}
          setContentId={setContentId}
          setContentMode={setContentMode}
          setBundleId={setBundleId}
          setBundleMode={setBundleMode}
          setCatalogId={setCatalogId}
          setCatalogMode={setCatalogMode}
          mode={mode}
          onEdit={onEdit}
          onView={onView}
          onSave={onSave}
          onCancel={onCancel}
          onNext={onNext}
          showColumnPopup={showColumnPopup}
          onCancelTableColumnSelection={props.onCancelTableColumnSelection}
          onSaveTableColumnSelection={props.onSaveTableColumnSelection}
          setSelectionColumns={props.setSelectionColumns}
          signUpApproval={signUpApproval}
          permissions={permissions}
          onViewHandlerList={onViewHandlerList}
          onEditHandlerList={onEditHandlerList}
        />
      )}
    </div>
  );
}

export default Bundle;
