import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import "../Sidebar/Navigation.css";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  SystemModulesEnum,
  SystemUsersEnum,
} from "../../constants/SystemUsers";
import {
  PermissionService,
  PermissionEnum,
} from "../../constants/permissionService";
import { Typography } from "@mui/material";

const Navigation = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const { collapseSidebar } = useProSidebar();
  const [nav, setNav] = useState([]);
  const [icon, setIcon] = useState(true);
  const [profileId, setProfileId] = useState();
  const [userRoleId, setUserRoleId] = useState();
  const [showSubMenu, setShowSubMenu] = useState(false);

  useEffect(() => {
    const currentUser = auth.getCurrentUser();
    if (currentUser) {
      sideBarMenuData(currentUser);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    collapseSidebar();
  }, []);

  const sideBarMenuData = async (currentUser) => {
    try {
      const roleId = auth.getRole();
      setUserRoleId(roleId);
      const user = auth.getCurrentUser();
      let modules = auth.getModules();

      let userProfileId = 0;

      switch (roleId) {
        case 5:
          userProfileId = user.distributorUserId;
          break;
        case 6:
          userProfileId = user.enterpriseGroupId;
          break;
        case 3:
        case 4:
        default:
          userProfileId = user.userId;
          break;
      }
      setProfileId(userProfileId);
      setNav(modules);
    } catch (e) {
      setNav([]);
    }
  };

  const renderSubMenu = (item) => {
    return (
      <Tooltip title={item.title} placement="right">
        <MenuItem
          active={pathname.startsWith(item.url)}
          suffix={item.name}
          component={<Link to={item.url} />}
          onClick={() => {
            setShowSubMenu(false);
          }}
        ></MenuItem>
      </Tooltip>
    );
  };

  /*istanbul ignore next */
  const getLinkUrl = (res) => {
    const permissions = PermissionService(res.moduleId);
    const isDistributor = userRoleId === SystemUsersEnum.DISTRIBUTOR_INTERNAL;
    const isEnterprise = userRoleId === SystemUsersEnum.ENTERPRISE_INTERNAL;

    if (
      (!permissions.includes(PermissionEnum.LIST) &&
        res.name !== "Dashboard") ||
      (isDistributor && res.name === SystemModulesEnum.DISTRIBUTORS) ||
      (isEnterprise && res.name === SystemModulesEnum.ENTERPRISE_GROUPS)
    ) {
      return `${res.profileUrl}${profileId}`;
    }

    return res.url;
  };

  return (
    <div className="my-sidebar">
      <Sidebar>
        <div
          className={icon ? "menu-collapsed-btn" : "menu-expanded-btn"}
          onClick={() => {
            collapseSidebar();
            setIcon(!icon);
          }}
        >
          {icon ? <ChevronRightRoundedIcon /> : <ChevronLeftRoundedIcon />}
        </div>
        <Menu>
          <div className="main-menu-div">
            <div className="sidebar-main">
              <div
                className={icon ? "menu-logo-div" : "menu-logo-div-expanded"}
              >
                <img
                  className="menu-logo"
                  src="/images/menu-logo.png"
                  alt="menu-logo"
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                />
              </div>
              {nav?.map((res) => {
                const uniqueSubMenuList = [
                  ...new Map(
                    res.childMenu.map((item) => [item["url"], item])
                  ).values(),
                ];
                return res.childMenu && res.childMenu.length ? (
                  <Tooltip title={res.title} placement="right">
                    <SubMenu
                      active={
                        uniqueSubMenuList.some((x) =>
                          pathname.startsWith(`${x.url}`)
                        )
                          ? "ps-menu-label active"
                          : ""
                      }
                      suffix={res.name}
                      icon={
                        <img
                          className={res.cssClass}
                          alt={res.name}
                          src={`/images/${res.menuImage}`}
                        />
                      }
                      onClick={() => {
                        setShowSubMenu(true);
                      }}
                    >
                      {uniqueSubMenuList.map((item) => {
                        return (!icon || showSubMenu) && renderSubMenu(item);
                      })}
                    </SubMenu>
                  </Tooltip>
                ) : (
                  <Tooltip title={res.toolTip} placement="right">
                    <Link
                      key={res.moduleId}
                      className="my-menu"
                      name="button"
                      data-testid={`menu-item-${res.moduleId}`}
                      to={getLinkUrl(res)}
                    >
                      <MenuItem
                        suffix={res.name}
                        active={
                          pathname.startsWith(`${res.url}`)
                            ? "ps-menu-label active"
                            : ""
                        }
                      >
                        <img src={"/images/" + res.menuImage} alt="menu-img" />
                      </MenuItem>
                    </Link>
                  </Tooltip>
                );
              })}
            </div>
          </div>

          <div>
            <Tooltip title="FAQ's" placement="right">
              <Link to="#">
                <MenuItem suffix="FAQ's">
                  <img src="/images/faqs.png" alt="faq-img" />
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip title="Logout" placement="right">
              <Link
                data-testid="signout-link"
                to="#"
                onClick={() => {
                  localStorage.clear();
                  // Clear the page content
                  document.body.innerHTML = "";

                  // Replace the current URL with a new one
                  window.history.replaceState(null, null, window.location.href);
                }}
              ></Link>
            </Tooltip>
          </div>
          <div>
            <MenuItem>
              <Typography className="version-text">Version : v0.25</Typography>
            </MenuItem>
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Navigation;
