import { ContentPublishStatus } from "../screens/Distributors/constants";

export const FILTER_OPTION = {
  INTERNALUSER_LIST: [
    {
      field: "role",
      label: "Role",
      options: [],
    },
    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
    {
      field: "accountStatus",
      label: "Account Status",
      options: [
        {
          value: "false",
          label: "Unlocked",
        },
        {
          value: "true",
          label: "Locked",
        },
      ],
    },
  ],

  CONTENT_LIST: [
    {
      field: "contentFillable",
      label: "Fillable",
      options: [
        {
          value: 2,
          label: "Fillable",
        },
        {
          value: 1,
          label: "Non-Fillable",
        },
      ],
    },
    {
      field: "contentType",
      label: "Content Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "userIds",
      label: "Distributors",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
    {
      field: "TypeOfContent",
      label: "Type of Content",
      options: [
        {
          value: 7,
          label: "AUDIO",
        },
        {
          value: 5,
          label: "EXCEL",
        },
        {
          value: 2,
          label: "FORMS_XFDL",
        },
        {
          value: 8,
          label: "GIF",
        },
        {
          value: 3,
          label: "JPG",
        },
        {
          value: 1,
          label: "PDF",
        },
        {
          value: 9,
          label: "PNG",
        },
        {
          value: 6,
          label: "PPT",
        },
        {
          value: 10,
          label: "VIDEO",
        },
        {
          value: 11,
          label: "WEBLINK",
        },
        {
          value: 4,
          label: "WORD",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Form",
      options: [],
    },
  ],
  DISTRIBUTOR_CONTENT_LIST: [
    {
      field: "contentFillable",
      label: "Fillable",
      options: [
        {
          value: 2,
          label: "Fillable",
        },
        {
          value: 1,
          label: "Non-Fillable",
        },
      ],
    },
    {
      field: "contentType",
      label: "Content Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
    {
      field: "TypeOfContent",
      label: "Type of Content",
      options: [
        {
          value: 7,
          label: "AUDIO",
        },
        {
          value: 5,
          label: "EXCEL",
        },
        {
          value: 2,
          label: "FORMS_XFDL",
        },
        {
          value: 8,
          label: "GIF",
        },
        {
          value: 3,
          label: "JPG",
        },
        {
          value: 1,
          label: "PDF",
        },
        {
          value: 9,
          label: "PNG",
        },
        {
          value: 6,
          label: "PPT",
        },
        {
          value: 10,
          label: "VIDEO",
        },
        {
          value: 11,
          label: "WEBLINK",
        },
        {
          value: 4,
          label: "WORD",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Form",
      options: [],
    },
  ],
  CATALOG_LIST: [
    {
      field: "contentType",
      label: "Catalog Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "userIds",
      label: "Distributors",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
  ],
  DISTRIBUTOR_CATALOG_LIST: [
    {
      field: "contentType",
      label: "Catalog Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Form",
      options: [],
    },
  ],
  BUNDLE_LIST: [
    {
      field: "contentType",
      label: "Bundle Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "userIds",
      label: "Distributors",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
  ],
  DISTRIBUTOR_BUNDLE_LIST: [
    {
      field: "contentType",
      label: "Bundle Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Form",
      options: [],
    },
  ],
  TYPE_OF_CONTENT_LIST: [
    {
      field: "contentFillable",
      label: "Fillable",
      options: [
        {
          value: 2,
          label: "Fillable",
        },
        {
          value: 1,
          label: "Non-Fillable",
        },
      ],
    },
    {
      field: "contentAssociationStatus",
      label: "Associated",
      options: [
        {
          value: 1,
          label: "Associated",
        },
        {
          value: 0,
          label: "Dis-Associated",
        },
      ],
    },
    {
      field: "contentType",
      label: "Catalog Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "publishStatus",
      label: "Published",
      options: [
        {
          label: "Draft",
          value: ContentPublishStatus.DRAFT,
        },
        {
          label: "Published",
          value: ContentPublishStatus.PUBLISHED,
        },
        {
          label: "Modified",
          value: ContentPublishStatus.MODIFIED,
        },
      ],
    },
    {
      field: "states",
      label: "State",
      options: [],
    },

    {
      field: "status",
      label: "Status",
      options: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 0,
          label: "Inactive",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Form",
      options: [],
    },
  ],
  ADD_CONTENT_LIST: [
    {
      field: "contentType",
      label: "Content Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "distributor",
      label: "Distributor",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "TypeOfContent",
      label: "Type of Content",
      options: [
        {
          value: 7,
          label: "AUDIO",
        },
        {
          value: 5,
          label: "EXCEL",
        },
        {
          value: 2,
          label: "FORMS_XFDL",
        },
        {
          value: 8,
          label: "GIF",
        },
        {
          value: 3,
          label: "JPG",
        },
        {
          value: 1,
          label: "PDF",
        },
        {
          value: 9,
          label: "PNG",
        },
        {
          value: 6,
          label: "PPT",
        },
        {
          value: 10,
          label: "VIDEO",
        },
        {
          value: 11,
          label: "WEBLINK",
        },
        {
          value: 4,
          label: "WORD",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Forms",
      options: [],
    },
  ],
  ADD_CATALOG_LIST: [
    {
      field: "contentType",
      label: "Content Type",
      options: [
        {
          value: 1,
          label: "Free",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
    },
    {
      field: "countries",
      label: "Country",
      options: [],
    },
    {
      field: "distributor",
      label: "Distributor",
      options: [],
    },
    {
      field: "lineOfBusiness",
      label: "LOB",
      options: [],
    },
    {
      field: "TypeOfContent",
      label: "Type of Content",
      options: [
        {
          value: 7,
          label: "AUDIO",
        },
        {
          value: 5,
          label: "EXCEL",
        },
        {
          value: 2,
          label: "FORMS_XFDL",
        },
        {
          value: 8,
          label: "GIF",
        },
        {
          value: 3,
          label: "JPG",
        },
        {
          value: 1,
          label: "PDF",
        },
        {
          value: 9,
          label: "PNG",
        },
        {
          value: 6,
          label: "PPT",
        },
        {
          value: 10,
          label: "VIDEO",
        },
        {
          value: 11,
          label: "WEBLINK",
        },
        {
          value: 4,
          label: "WORD",
        },
      ],
    },
    {
      field: "typeOfForm",
      label: "Type of Forms",
      options: [],
    },
    {
      field: "DocumentTypes",
      label: "Type - Content/Catalog",
      options: [
        {
          value: 1,
          label: "Content",
        },
        {
          value: 2,
          label: "Catalog",
        },
      ],
    },
  ],
};
