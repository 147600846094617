import { useContext, createContext } from "react";

export const ApprovalInit = {
  isEditMode: false,
  setEditMode(val) {
    this.isEditMode = val;
  },
};

export const ApprovalContext = createContext(ApprovalInit);

export const useApprovalContext = () => useContext(ApprovalContext);
