import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import "../Dashboard/Dashboard.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ApiServices from "../../services/ApiServices";
import Tooltip from "../../common/workarounds/tooltip/PatchTooltip";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  FEATURE_LIST,
  ROLE_LIST,
  EXPORT_ROLE,
} from "../../constants/api-constants";
import Popover from "@mui/material/Popover";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoader";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
  GetSelectedColumns,
} from "../../utilities/SelectionFilter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import DataTable from "../Tables/DataTable/DataTable";
import AddEditRoleModal from "./AddEditRoleModal";
import { roleFilterOptions } from "../../constants/common";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";

const ManageRoles = (props) => {
  const { showLoader } = useLoading();
  const [adminTable, setAdminTableData] = useState([]);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [featureListDetails, setFeatureListDetails] = useState([]);
  const [featureListDetails1, setFeatureListDetails1] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedApplicable, setSelectedApplicable] = useState([]);

  const [query, setQuery] = useState({
    SearchText: "",
    SortType: "desc",
    SortColumn: "RoleId",
    PageNumber: 1,
    PageSize: 10,
    IgnorePaging: false,
    Filter: {
      ApplicableFor: [1, 2, 3, 4, 5, 6, 7],
      Status: [0, 1],
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState("false");

  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setSelectionColumns();
    adminDetails();
    ClearSelectionData();
    featureDetails();
  }, []);

  useEffect(() => {
    adminDetails();
  }, [query]);

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const featureDetails = async () => {
    try {
      const featureList = await ApiServices.httpGet(`${FEATURE_LIST}`);
      setFeatureListDetails(featureList?.result);
      setFeatureListDetails1(featureList?.result);
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const adminDetails = async () => {
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${ROLE_LIST}/${JSON.stringify(query)}`
      );
      showLoader(false);
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("roleId");
      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.map((ele) => {
          if (checkedIds.includes(ele.roleId)) {
            ele.isChecked = true;
          } else {
            ele.isChecked = false;
          }
          return ele;
        });
      }
      // ends here
      setAdminTableData(
        adminData?.result?.records.map((data) => {
          return {
            ...data,
            roleTypes: data.roleTypes.map((r) => {
              return { roleTypeId: r.roleTypeId, name: r.userTypeName };
            }),
          };
        })
      );
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("roleId");
      let queryState = { ...query };
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.Filter.selectedColumns = GetSelectedColumns(9);
      const exportedContent = await ApiServices.httpGet(
        `${EXPORT_ROLE}/${JSON.stringify(queryState)}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
      adminDetails();
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onFilterRoleChangeHandler = (values) => {
    setSelectedApplicable(values);
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };

  const applyFilterHandler = () => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        Filter: {
          ...prevState.filter,
          ApplicableFor: selectedApplicable,
          Status: selectedStatus,
        },
      };
    });
  };

  const clearFilterHandler = () => {
    setSelectedApplicable([]);
    setSelectedStatus([]);
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, role: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols.role ? selectedCols.role : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  return (
    <div className="dashboard-main">
      <div
        className="menu-bar"
        style={{
          display: "flex",

          height: "100%",
        }}
      >
        <div className="welcome">
          <div className="welcome-main">
            <div className="welcome-message">
              <h5>Roles Management</h5>
            </div>
            <div className="welcome-widgets">
              <div>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                >
                  <div className="expanded-filter">
                    <div className="filter-options">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                          >
                            Filters will be applied to your grid
                          </ListSubheader>
                        }
                      >
                        <Accordion
                          expanded={expanded === "role"}
                          onChange={filterAccordianHandleChange("role")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Role
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="role"
                              options={roleFilterOptions}
                              onChange={onFilterRoleChangeHandler}
                              selectedValues={selectedApplicable}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "status"}
                          onChange={filterAccordianHandleChange("status")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Status
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="status"
                              options={[
                                { value: 1, label: "Active" },
                                { value: 0, label: "Inactive" },
                              ]}
                              onChange={onFilterStatusChangeHandler}
                              selectedValues={selectedStatus}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </List>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant="outlined"
                        className="action-button mr-4"
                        onClick={clearFilterHandler}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        className="filter-buttons action-button"
                        onClick={() => {
                          applyFilterHandler();
                          handleClose();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>

              <AddEditRoleModal
                isEventListner={true}
                featureListDetails={featureListDetails}
                featureListDetails1={featureListDetails1}
                adminDetails={adminDetails}
                isAdd={true}
                isView={false}
                onClick={props.onClick}
              />

              <Tooltip placement="bottom" title="Filter">
                <FilterAltOutlinedIcon onClick={handleClick} />
              </Tooltip>

              <Tooltip placement="bottom" title="Change View">
                <BorderColorIcon
                  onClick={() => {
                    setTableColumnModalVisibility(true);
                  }}
                  data-testid="TableColumnSelectionIcon"
                />
              </Tooltip>

              <Tooltip placement="bottom" title="Export to Excel">
                <IosShareRoundedIcon
                  data-testid="IosShareRoundedIcon"
                  onClick={handleExport}
                />
              </Tooltip>
            </div>
          </div>

          <DataTable
            adminDetails={adminDetails}
            featureListDetails={featureListDetails}
            featureListDetails1={featureListDetails1}
            uniqueKey="roleId"
            onCheckboxChange={(e, value) => {
              const { checked } = e.target;

              if (value === "all") {
                setAdminTableData((items) => {
                  SelectionFilter(items, "multiple", checked, "roleId");
                  return items.map((data) => {
                    return {
                      ...data,
                      isChecked: checked,
                    };
                  });
                });
              } else {
                setAdminTableData((items) => {
                  return items.map((user) => {
                    let userRecord = { ...user };
                    if (user.roleId === value) {
                      SelectionFilter(user, "single", checked, "roleId");
                      userRecord.isChecked = checked;
                    }
                    return userRecord;
                  });
                });
              }
            }}
            columns={selectedColumns}
            query={query}
            handleChangePage={handleChangePageNumber}
            adminTable={adminTable}
            totalPages={totalPages}
            pageSize={pageSize}
            page={query.PageNumber}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            showView={true}
            showEdit={true}
          />

          {tableColumnModalVisibility && (
            <TableColumnModal
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageRoles;
