function Rectangle(props) {
  const { rectangle } = props;
  return (
    <div
      style={{
        width: rectangle.Width,
        height: rectangle.Height,
        position: "absolute",
        top: rectangle.SetTop,
        left: rectangle.SetLeft + 10,
        border: rectangle.StrokeThickness,
        borderStyle: "solid",
        borderColor: rectangle.Stroke.Color,
      }}
    ></div>
  );
}

export default Rectangle;
