import React from "react";
import { Checkbox } from "@mui/material";

function CheckBox(props) {
  const { item, defaultValue, isRequired } = props;
  const val = parseInt(item.Width / 20);
  const onNotRequired = defaultValue
    ? "xfdlcheckbox-filled"
    : "xfdlcheckbox-empty";
  const onNotReq = defaultValue ? "black" : "blue";
  return (
    <Checkbox
      type="checkbox"
      title={item.ToolTip?.Content || ""}
      name={item.Name}
      checked={defaultValue === "true" ? true : false}
      onChange={(e) => {
        props.checkBoxHandler(e, item.Name, isRequired);
      }}
      className={isRequired ? "xfdlcheckbox-req" : onNotRequired}
      disableRipple
      sx={{ color: isRequired ? "red" : onNotReq }}
      style={{
        borderRadius: "1px",
        backgroundColor: "white",
        width: item.Width,
        height: item.Height - val - val,
        position: "absolute",
        top: props.isBgImage ? `${item.SetTop}px` : `${item.SetTop + val}px`,
        left: props.isBgImage
          ? `${item.SetLeft}px`
          : `${item.SetLeft + 10 - val}px`,
      }}
    />
  );
}

export default CheckBox;
