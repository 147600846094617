import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./ConfirmationWithRejectReason.css";
import { TextField } from "@mui/material";
import { AssociationStatusEnum } from "../Modal/AssociationStatusEnum";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 616,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
};

function ConfirmationWithRejectReason(props) {
  const { showReason, options, actionName } = props;
  const [reason, setReason] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="confirmation-with-reason"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" sx={{ mb: 2 }}>
          {props.title}
        </Typography>
        {props.description && (
          <Typography id="modal-modal-description" sx={{ mb: 2, mt: 2 }}>
            {props.description}
          </Typography>
        )}

        {showReason && (
          <>
            <div className="reason-div mt-2">
              <select
                id="previewType"
                name="previewType"
                data-testid="preview-type"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                className="form-label form-control input-border custom-sel-ect cus-cus-avl"
              >
                {actionName === AssociationStatusEnum.Rejected && (
                  <option>Select Reject Reason</option>
                )}
                {actionName === AssociationStatusEnum.Approved && (
                  <option>Select Role</option>
                )}
                {options?.map((res) => {
                  return (
                    <option key={res.value} value={res.value}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {actionName === AssociationStatusEnum.Rejected && (
              <div className="reason-div mt-2">
                <TextField
                  multiline
                  value={additionalDetails}
                  className="reason-div-textfield"
                  data-testid="reason_textbox"
                  rows={8}
                  onChange={(e) => {
                    setAdditionalDetails(e.target.value);
                  }}
                  style={{ width: "100%", padding: 1 }}
                />
              </div>
            )}
          </>
        )}

        <div className="action-btn-div mt-4">
          <Button
            className="action-button"
            variant="outlined"
            onClick={(e) => {
              setReason("");
              setAdditionalDetails("");
              props.onCancel(e);
            }}
            data-testid="cancel-action"
          >
            {props.cancelButtonLabel}
          </Button>
          <Button
            className="action-button ml-4"
            variant="contained"
            onClick={(e) => {
              setReason("");
              setAdditionalDetails("");
              props.onConfirm(e, reason, additionalDetails);
            }}
            data-testid="confirm-action"
            disabled={reason === "" || additionalDetails === ""}
          >
            {props.confirmButtonLabel}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default ConfirmationWithRejectReason;
