import React, { useState } from "react";
import getClassNames from "./classnames";
import Tag from "./Tag";
import { useDidUpdateEffect } from "./useDidUpdateEffect";
import "./styles.css";

function TagsInput(props) {
  const {
    name,
    placeHolder,
    value,
    onChange,
    onBlur,
    separators,
    disableBackspaceRemove,
    onExisting,
    onRemoved,
    disabled,
    isEditOnRemove,
    beforeAddValidate,
    onKeyUp,
    classNames,
  } = props;

  const defaultSeparators = ["Enter", ","];

  const [tags, setTags] = useState(value || []);

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);

  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value);
    }
  }, [value]);

  const handleOnKeyUp = (e) => {
    e.stopPropagation();

    const text = e.target.value;

    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      (e.key === "Backspace" || e.key === "Delete")
    ) {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : "";
      setTags([...tags.slice(0, -1)]);
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();
      if (beforeAddValidate && !beforeAddValidate(text, tags)) return;

      if (tags.includes(text)) {
        onExisting && onExisting(text);
        return;
      }
      setTags([...tags, text]);
      e.target.value = "";
    }
  };

  const onTagRemove = (text) => {
    setTags(tags.filter((tag) => tag !== text));
    onRemoved && onRemoved(text);
  };

  return (
    <div
      aria-labelledby={name}
      className={`rti--container ${disabled && "disabled"}`}
      style={{
        padding: "6.7px",
        marginTop: "-2px",
        height: "100px",
        overflowY: "scroll",
      }}
    >
      {tags.map((tag) => (
        <Tag
          key={tag}
          className={classNames?.tag}
          text={tag}
          remove={onTagRemove}
          disabled={disabled}
        />
      ))}

      {!disabled && (
        <input
          className={getClassNames("rti--input", classNames?.input)}
          type="text"
          name={name}
          placeholder={placeHolder}
          onKeyDown={handleOnKeyUp}
          onBlur={onBlur}
          disabled={disabled}
          onKeyUp={onKeyUp}
          autocomplete="off"
          data-testid={`rti--input-${name}`}
        />
      )}
    </div>
  );
}

export default TagsInput;
