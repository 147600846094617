// WARNING: don't change the order of the file, if you want to add a module just add an object inside MenuListItems at last.
// WARNING: don't change the contents of the MenuList Objects's
// Whenever a new menu or module has been introduced in Roles and Permissions don't forget to add here. else will not be visible
// display order key is mandatory for order in left side menu, keep setup objects display order the highest number (after all the objects) this way it will be at last

export const MenuListItems = [
  {
    moduleId: 0,
    pModuleId: null,
    name: "Dashboard",
    url: "/dashboard",
    cssClass: "admin-css",
    toolTip: "Dashboard",
    displayOrder: 1,
    menuImage: "dashboard.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 1,
    pModuleId: 0,
    name: "Users",
    url: "/users",
    cssClass: "admin-css",
    toolTip: "Users",
    displayOrder: 2,
    menuImage: "admin.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 2,
    pModuleId: 0,
    name: "Distributors",
    url: "/distributors",
    profileUrl: "/distributor/",
    cssClass: "admin-css",
    toolTip: "Distributor & Publisher",
    displayOrder: 3,
    menuImage: "distributors.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 3,
    pModuleId: 0,
    name: "Enterprise Groups",
    url: "/enterprisegroups",
    profileUrl: "/enterprisegroup/",
    cssClass: "admin-css",
    toolTip: "Enterprise Groups",
    displayOrder: 4,
    menuImage: "enterprisegroup.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 5,
    pModuleId: 0,
    name: "Subscribers",
    url: "/subscribers",
    cssClass: "admin-css",
    toolTip: "Subscribers",
    displayOrder: 5,
    menuImage: "subscriber.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 6,
    pModuleId: 0,
    name: "Reports",
    url: "/Reports",
    cssClass: "admin-css",
    toolTip: "Reports",
    displayOrder: 12, // Always keep it second last as per OFM-4155
    menuImage: "report.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 7,
    pModuleId: 0,
    name: "Approval",
    url: "/approvals",
    cssClass: "admin-css",
    toolTip: "Approvals",
    displayOrder: 7,
    menuImage: "approvals.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 8,
    pModuleId: 0,
    name: "Content",
    url: "/content",
    cssClass: "admin-css",
    toolTip: "Content",
    displayOrder: 8,
    menuImage: "contents.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
    title: "Content",
  },
  {
    moduleId: 11,
    pModuleId: 0,
    name: "Setup",
    url: "/setup",
    cssClass: "admin-css",
    toolTip: "Setup",
    displayOrder: 13,
    menuImage: "setup.png",
    features: [],
    childMenu: [],
    subMenu: [],
    isSubMenu: false,
    parentModuleName: null,
    title: "Setup",
  },
  {
    moduleId: 12,
    pModuleId: 0,
    name: "Catalog",
    url: "/catalog",
    cssClass: "admin-css",
    toolTip: "Catalog",
    displayOrder: 9,
    menuImage: "catalogues.png",
    features: [],
    childMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 56,
    pModuleId: 0,
    name: "Bundle",
    url: "/bundle",
    cssClass: "admin-css",
    toolTip: "Bundle",
    displayOrder: 10,
    menuImage: "bundle.png",
    features: [],
    childMenu: [],
    isSubMenu: false,
    parentModuleName: null,
  },
  {
    moduleId: 37,
    pModuleId: 2,
    name: "Distributor Internal User",
    url: "/distributors",
    cssClass: "admin-css",
    toolTip: "Distributors",
    displayOrder: 2,
    menuImage: "distributors.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Distributors",
  },
  {
    moduleId: 38,
    pModuleId: 2,
    name: "Distributor Content",
    url: "/distributors",
    cssClass: "admin-css",
    toolTip: "Distributors",
    displayOrder: 2,
    menuImage: "distributors.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Distributors",
  },
  {
    moduleId: 39,
    pModuleId: 2,
    name: "Distributor Catalog",
    url: "/distributors",
    cssClass: "admin-css",
    toolTip: "Distributors",
    displayOrder: 2,
    menuImage: "distributors.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Distributors",
  },
  {
    moduleId: 57,
    pModuleId: 2,
    name: "Distributor Bundle",
    url: "/distributors",
    cssClass: "admin-css",
    toolTip: "Distributor Bundle",
    displayOrder: 2,
    menuImage: "distributors.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Distributors",
  },
  {
    moduleId: 40,
    pModuleId: 3,
    name: "Enterprise Internal User",
    url: "/enterprisegroups",
    cssClass: "admin-css",
    toolTip: "Enterprise Groups",
    displayOrder: 3,
    menuImage: "enterprisegroup.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Enterprise Groups",
  },
  {
    moduleId: 41,
    pModuleId: 3,
    name: "Enterprise Content",
    url: "/enterprisegroups",
    cssClass: "admin-css",
    toolTip: "Enterprise Groups",
    displayOrder: 3,
    menuImage: "enterprisegroup.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Enterprise Groups",
  },
  {
    moduleId: 42,
    pModuleId: 3,
    name: "Enterprise Catalog",
    url: "/enterprisegroups",
    cssClass: "admin-css",
    toolTip: "Enterprise Groups",
    displayOrder: 4,
    menuImage: "enterprisegroup.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Enterprise Groups",
  },
  {
    moduleId: 43,
    pModuleId: 11,
    name: "Role Management",
    url: "/role-management",
    cssClass: "admin-css",
    toolTip: "Role Mangement",
    displayOrder: 1,
    menuImage: "setup.png",
    features: [],
    childMenu: [],
    parentModuleName: "Setup",
  },
  {
    moduleId: 44,
    pModuleId: 11,
    name: "Module Management",
    url: "/module-management",
    cssClass: "admin-css",
    toolTip: "Module Management",
    displayOrder: 2,
    menuImage: "setup.png",
    features: [],
    childMenu: [],
    parentModuleName: "Setup",
  },
  {
    moduleId: 49,
    pModuleId: 2,
    name: "Distributor Basic Details",
    parentModuleName: "Distributors",
    url: "/distributor",
    cssClass: "admin-css",
    toolTip: "Basic Details",
    displayOrder: 1,
    menuImage: "",
    features: [],
    childMenu: [],
    isSubMenu: true,
  },
  {
    moduleId: 50,
    pModuleId: 2,
    name: "Distributor Association Details",
    parentModuleName: "Distributors",
    url: "/distributor",
    cssClass: "admin-css",
    toolTip: "Association Details",
    displayOrder: 1,
    menuImage: "",
    features: [],
    childMenu: [],
    isSubMenu: true,
  },
  {
    moduleId: 62,
    pModuleId: 2,
    name: "Distributor Subscription Details",
    parentModuleName: "Distributors",
    url: "/distributor",
    cssClass: "admin-css",
    toolTip: "Subscription Details",
    displayOrder: 1,
    menuImage: "",
    features: [],
    childMenu: [],
    isSubMenu: true,
  },
  {
    moduleId: 51,
    pModuleId: 3,
    name: "Enterprise Basic Details",
    parentModuleName: "Enterprise Groups",
    url: "/enterprisegroup",
    cssClass: "admin-css",
    toolTip: "Basic Details",
    displayOrder: 1,
    menuImage: "",
    features: [],
    childMenu: [],
    isSubMenu: true,
  },
  {
    moduleId: 52,
    pModuleId: 3,
    name: "Distributor & Publisher Management",
    parentModuleName: "Enterprise Groups",
    url: "/enterprisegroup",
    cssClass: "admin-css",
    toolTip: "Distributor & Publisher Management",
    displayOrder: 1,
    menuImage: "",
    features: [],
    childMenu: [],
    isSubMenu: true,
  },
  {
    moduleId: 58,
    pModuleId: 3,
    name: "Enterprise Bundle",
    url: "/enterprisegroups",
    cssClass: "admin-css",
    toolTip: "Enterprise Groups",
    displayOrder: 3,
    menuImage: "enterprisegroup.png",
    features: [],
    childMenu: [],
    isSubMenu: true,
    parentModuleName: "Enterprise Groups",
  },
  {
    moduleId: 64,
    pModuleId: 8,
    name: "Individual Content",
    url: "/content",
    cssClass: "admin-css",
    toolTip: "Individual Content",
    displayOrder: 1,
    menuImage: "contents.png",
    features: [],
    childMenu: [],
    parentModuleName: "Content",
  },
  {
    moduleId: 59,
    pModuleId: 8,
    name: "Mass Upload Content",
    url: "/manage-documents",
    cssClass: "admin-css",
    toolTip: "Mass Upload Content",
    displayOrder: 2,
    menuImage: "manage-documents.png",
    features: [],
    childMenu: [],
    parentModuleName: "Content",
  },
];
