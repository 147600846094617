import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Popover,
  Box as MaterialBox,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../Modal/Modal.css";
import "../Modal/UploadUserModal.css";
import React, { useState, useRef, useEffect } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Link, useHistory } from "react-router-dom";
import { useLoading } from "../../hooks/useLoader";
import { toast } from "react-toastify";
import ApiServices from "../../services/ApiServices";
import moment from "moment";
import {
  BULK_UPLOAD_CONTENT_SUBSCRIBERS,
  DISTRIBUTOR_INTERNAL_USER_BULK_UPLOAD,
  MASS_UPLOAD_CONTENT,
  SUBSCRIBER_BULK_UPLOAD,
  SUBSCRIBER,
  MASS_UPLOAD_CONTENT_DOWNLOAD_PROCESSED_REQUEST,
} from "../../constants/api-constants";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import { GetAllSelectedIds } from "../../utilities/SelectionFilter";
import { MandatoryField } from "../MandatoryFieldsIcon/MandatoryField";
import { EmailSchema } from "../../common/ValidationSchema/ValidationSchema";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { useFormik } from "formik";
import { useAuth } from "../../hooks/useAuth";
import { usePageMode } from "../../hooks/usePageMode";
import { SystemUsersEnum } from "../../constants/SystemUsers";
import { subscriberDoesNotExists } from "../../screens/Distributors/constants";
import Confirmation from "../Confirmation/Confirmation";
import RefreshIcon from "@mui/icons-material/Refresh";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

let response1;
const UploadUserModal = (props) => {
  const { bulkUploadTemplate, openMassUploadPopup } = props;
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [result, setResult] = useState(null);
  const { showLoader } = useLoading();
  const [openMassReuqestDownloader, setOpenMassReuqestDownloader] =
    useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [isLoadingProcessedData, setIsLoadinngProcessedData] = useState(false);

  const style_new = {
    ...style,
    width: "70%",
    backgroundColor: "#fff",
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };
  const handleClose = () => {
    setFile(null);
    setResult(null);
    setOpen(false);
    if (props.from === "mass-upload-content" && props.setOpenMassUploadPopup) {
      props.setOpenMassUploadPopup(false);
    }
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const [openSubs, setOpenSubs] = useState(false);
  const handleOpenSubs = () => setOpenSubs(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openContentProcessConfirmation, setOpenContentProcessConfirmation] =
    useState(false);

  const [confirmationTitle, setConfirmationTitle] = useState(
    "Mass Upload is in Process"
  );
  const [confirmationMessage, setConfirmationMessage] = useState(
    "The mass upload process may take some time to complete. Feel free to continue with your other tasks while we process your content(s)."
  );
  const [confirmationLabel, setConfirmationLabel] = useState("Proceed");
  const handleCloseSubs = () => {
    formik.setFieldValue("primaryEmail", "");
    setOpenSubs(false);
  };

  const auth = useAuth();
  const page = usePageMode();
  const history = useHistory();

  const [query, setQuery] = useState({
    searchText: "",
    PageNumber: 1,
    PageSize: 1,
    SortType: "desc",
    SortColumn: "userId",
    IsSearchInAllQuery: true,
    filter: {},
  });
  const handleAssociation = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(
        `${SUBSCRIBER}/find?emailId=${query.searchText}`
      );

      if (response.responseStatus?.isSuccess) {
        let updatedSub = response.result;
        let subExistsError = checkAddSub(auth, updatedSub);

        await performNextStep(
          subExistsError,
          updatedSub,
          props,
          handleCloseSubs
        );
      } else {
        whenSubscriberNotExists(response, setOpenConfirmation, showLoader);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const aRef = useRef("");

  const formik = useFormik({
    initialValues: {
      primaryEmail: "",
    },
    enableReinitialize: true,
    validationSchema: EmailSchema,
  });

  const handleUpload = async () => {
    if (window.location.pathname === "/subscribers") {
      await subscriberUpload(showLoader, file, aRef, setResult, props);
    } else if (props.from === "mass-upload-content") {
      await massUploadContent();
    } else if (props.from === "content-manage-subscribers") {
      await contentManageSubscribersUpload(
        showLoader,
        file,
        aRef,
        setResult,
        props,
        history
      );
    } else {
      await commonUpload(showLoader, props, file, aRef, setResult);
    }
  };

  const massUploadContent = async () => {
    try {
      showLoader(true);
      const formData = new FormData();
      formData.append("File", file);
      formData.append("FileName", file.name);
      formData.append("UserId", props.userId);
      const response = await ApiServices.httpPostFile(
        MASS_UPLOAD_CONTENT,
        formData
      );
      showLoader(false);
      setOpenMassReuqestDownloader(true);
      props.getContentList();
      handleClose();
      if (response.responseStatus?.isSuccess) {
        aRef.current.value = null;
        setResult(response.result);
        setConfirmationTitle("Mass Upload is in Process");
        setConfirmationMessage(
          "The mass upload process may take some time to complete. Feel free to continue with your other tasks while we process your content(s)."
        );
        setConfirmationLabel("Proceed");
        setOpenContentProcessConfirmation(true);
        let timesRun = 0;
        let intervalForMassUploadContent = setInterval(async () => {
          timesRun += 1;
          let response = await getRequests(false);
          let currentFile = response?.find((x) => x.fileName === file.name);
          if (currentFile && currentFile.status) {
            clearInterval(intervalForMassUploadContent);
            toast.success(
              file.name +
                ": The Content Mass Upload has been processed successfully. To see the results, click on “View Processed Requests” on the Mass Upload screen.",
              {
                autoClose: false, // Keeps the toast visible until manually closed
              }
            );
          }
          // stop interval after 30 mins
          if (timesRun === 180) {
            clearInterval(intervalForMassUploadContent);
          }
        }, 10000);
      } else {
        toast.error(response.responseStatus?.error);
      }
    } catch (event) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const getFileExtension = (fname) => {
    return fname
      .slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1)
      .toLowerCase();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxFileSize =
      props.from === "mass-upload-content"
        ? 5 * 1024 * 1024 // 5MB in bytes
        : 2 * 1024 * 1024; // 2MB in bytes
    if (selectedFile.size > maxFileSize) {
      toast.error(
        `File size exceeds maximum ${
          props.from === "mass-upload-content" ? "5MB" : "2MB"
        } allowed. Kindly reduce the size of the file before proceeding`
      );
      return;
    }
    const fileExtensions = ["xls", "xlsx"];
    let ext = getFileExtension(selectedFile.name);
    if (!fileExtensions.includes(ext)) {
      toast.error("Please select xls/xlsx file");
      return;
    }

    setFile(e.target.files[0]);
    setResult(null);
  };

  const downloadHandler = (e) => {
    ExportToExcelHandler(
      result.file.fileContents,
      result.file.fileDownloadName
    );
  };

  const downloadTemplate = () => {
    let link = document.createElement("a");
    let filePath = bulkUploadTemplate;
    link.href = filePath;
    link.download = filePath.substring(filePath.lastIndexOf("/") + 1);
    link.click();
  };

  /*istanbul ignore next */
  useEffect(() => {
    if (openMassReuqestDownloader) {
      getRequests();
    }
  }, [openMassReuqestDownloader]);

  /*istanbul ignore next */
  useEffect(() => {
    setOpen(openMassUploadPopup);
  }, [openMassUploadPopup]);

  /*istanbul ignore next */
  const getRequests = async (includeLoader = true) => {
    try {
      setProcessedData([]);
      setIsLoadinngProcessedData(true);
      if (includeLoader) {
        showLoader(true);
      }
      const response = await ApiServices.httpGet(
        `${MASS_UPLOAD_CONTENT}/${props.userId}`
      );
      if (response?.responseStatus?.isSuccess) {
        setProcessedData(response?.result);
        setIsLoadinngProcessedData(false);
        if (includeLoader) {
          props.getContentList();
          showLoader(false);
        }
        return response?.result;
      } else {
        setProcessedData([]);
        setIsLoadinngProcessedData(false);
        props.getContentList();
        if (includeLoader) {
          showLoader(false);
        }
      }
      return [];
    } catch (err) {
      if (includeLoader) {
        showLoader(false);
      }
      setIsLoadinngProcessedData(false);
      console.log("err", err);
      return [];
    }
  };

  /*istanbul ignore next */
  const downloadProcessedRequests = async (requestId, isError) => {
    try {
      showLoader(true);
      let payload = {
        userId: props.userId,
        requestId: requestId,
        isError: isError,
      };
      const response = await ApiServices.httpPost(
        MASS_UPLOAD_CONTENT_DOWNLOAD_PROCESSED_REQUEST,
        payload
      );
      if (response?.responseStatus?.isSuccess) {
        ExportToExcelHandler(
          response.result.file.fileContents,
          response.result.file.fileDownloadName
        );
      } else {
        toast.error(response.responseStatus.error);
      }
      showLoader(false);
    } catch (err) {
      showLoader(false);
      console.log("err", err);
    }
  };

  return (
    <>
      {!props.from && props.from !== "content-manage-subscribers" && (
        <Tooltip placement="bottom" title={"Bulk Upload Users"}>
          <CloudUploadOutlinedIcon
            data-testid="CloudUploadOutlinedIcon"
            onClick={
              [SystemUsersEnum.SUPERADMIN, SystemUsersEnum.ADMIN].includes(
                auth.getRole()
              )
                ? handleOpen
                : handlePopoverOpen
            }
          />
        </Tooltip>
      )}
      {props.from === "content-manage-subscribers" && (
        <Tooltip placement="bottom" title={"Bulk Upload Content Subscribers"}>
          {props.isSelected ? (
            <CloudUploadOutlinedIcon
              data-testid="CloudUploadOutlinedIconEnabled"
              onClick={handleOpen}
            />
          ) : (
            <CloudUploadOutlinedIcon
              data-testid="CloudUploadOutlinedIconDisabled"
              style={{ backgroundColor: "#83b0c7" }}
            />
          )}
        </Tooltip>
      )}

      {!props.from && props.from !== "content-manage-subscribers" && (
        <Popover
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{
            marginRight: "inherit",
            paddingRight: "inherit",
            marginTop: "100px",
          }}
          // id={id}
          open={popoverOpen}
          // anchorEl={anchorEl}
          onClose={handlePopoverClose}
        >
          <div className="expanded-options">
            <Button
              className="button-text"
              data-testid="AssociateSubscriberButton"
              onClick={handleOpenSubs}
            >
              Associate Subscriber
            </Button>
            <Button
              className="button-text"
              data-testid="MassUploadSubscriberButton"
              onClick={handleOpen}
            >
              Mass Upload Subscriber
            </Button>
          </div>
        </Popover>
      )}
      {props.from === "mass-upload-content" && (
        <Button
          data-testid="mass-upload-content-btn"
          className="action-button"
          type="button"
          variant="contained"
          onClick={handleOpen}
        >
          Upload Matching Spreadsheet
        </Button>
      )}
      {props.from === "mass-upload-content" && (
        <Button
          data-testid="view-processed-requests-button-content"
          className="action-button"
          type="button"
          variant="contained"
          onClick={() => {
            setOpenMassReuqestDownloader(true);
          }}
        >
          View Procesed Requests
        </Button>
      )}

      {/* Download Processed Request Modal starts here */}
      <Modal
        open={openMassReuqestDownloader}
        onClose={() => {
          setOpenMassReuqestDownloader(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MaterialBox style={style_new} className="custom-modal">
          <div className="modal-header">
            <div className="modal-header-left">Processed Requests</div>
            <div className="modal-header-right">
              <CloseOutlinedIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenMassReuqestDownloader(false);
                }}
              />
            </div>
          </div>
          <div className="modal-body file-upload-body">
            <div className="modal-body downloader-request">
              <div className="refresh-mod">
                <Tooltip placement="bottom" title="Refresh list">
                  <RefreshIcon
                    onClick={() => {
                      getRequests();
                    }}
                  />
                </Tooltip>
              </div>
              <Table aria-label="simple table" className="processed-table">
                <TableHead>
                  <TableRow>
                    <TableCell>FILE NAME</TableCell>
                    <TableCell>DATE</TableCell>
                    <TableCell>TOTAL CONTENT</TableCell>
                    <TableCell>SUCCESS CONTENT</TableCell>
                    <TableCell>ERROR CONTENT</TableCell>
                    <TableCell>STATUS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoadingProcessedData && !processedData?.length && (
                    <TableRow>
                      <TableCell className="no-data-found" colSpan={5}>
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                  {processedData?.map((item) => {
                    return (
                      <TableRow key="1">
                        <TableCell>{item.fileName}</TableCell>
                        <TableCell>
                          {item.createdOn === null
                            ? ""
                            : moment(item.createdOn).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell>{item.totalRecords}</TableCell>
                        <TableCell>
                          {item.successCount ? (
                            <Tooltip placement="bottom" title="Download">
                              <span
                                className="download-link"
                                onClick={() => {
                                  downloadProcessedRequests(
                                    item.requestId,
                                    false
                                  );
                                }}
                              >
                                {item.successCount}
                              </span>
                            </Tooltip>
                          ) : (
                            item.successCount
                          )}
                        </TableCell>
                        <TableCell>
                          {item.failCount ? (
                            <Tooltip placement="bottom" title="Download">
                              <span
                                className="error-link"
                                onClick={() => {
                                  downloadProcessedRequests(
                                    item.requestId,
                                    true
                                  );
                                }}
                              >
                                {item.failCount}
                              </span>
                            </Tooltip>
                          ) : (
                            item.failCount
                          )}
                        </TableCell>
                        <TableCell>
                          <span
                            className={
                              item.status ? "processed" : "in-processing"
                            }
                          >
                            {item.status ? "PROCESSED" : "PROCESSING"}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
          <div className="modal-footer my-modal-footer"></div>
        </MaterialBox>
      </Modal>
      {/* Download Processed Request Modal ends here */}
      <Confirmation
        open={openContentProcessConfirmation}
        title={confirmationTitle}
        description={confirmationMessage}
        cancelButtonNotRequired={true}
        onConfirm={() => {
          setOpenContentProcessConfirmation(false);
        }}
        confirmButtonLabel={confirmationLabel}
      />

      <Confirmation
        open={openConfirmation}
        title={`Confirmation`}
        description={subscriberDoesNotExists}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={() => {
          setOpenConfirmation(false);
          localStorage.setItem(
            "DefaultSubscriberEmail",
            formik.values.primaryEmail
          );
          handleCloseSubs();
          page.setEditMode(true);
          page.setBreadcrumbRootNode({
            title: props.routeData?.title,
            path: props.routeData?.listRoute,
          });
          history.push(props.routeData?.addRoute);
        }}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Create"
      />
      <Modal
        open={openSubs}
        onClose={handleCloseSubs}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="file-upload-modal">
          <div className="modal-header">
            <div className="modal-header-left">{"Associate Subscriber"}</div>
            <div className="modal-header-right">
              <CloseOutlinedIcon
                onClick={handleCloseSubs}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="modal-body">
            <div>
              <label htmlFor="primaryEmail">Primary Email Address</label>
              <MandatoryField />
              <input
                id="primaryEmail"
                name="primaryEmail"
                className="form-label form-control"
                type="email"
                placeholder="Enter primary email address"
                onChange={(e) => {
                  setQuery((prevState) => {
                    return { ...prevState, searchText: e.target.value };
                  });
                  formik.handleChange(e);
                }}
                value={formik.values.primaryEmail}
                autoComplete="off"
              />
              <InlineMessage error={formik.errors.primaryEmail} />
            </div>
          </div>
          <div className={`modal-footer "modal-header-right"`}>
            <div modal-footer>
              <Button
                variant="outlined"
                className="action-button"
                onClick={() => {
                  handleCloseSubs();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="action-button"
                onClick={async () => handleAssociation()}
                disabled={!formik.isValid}
              >
                Associate Subscriber
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="file-upload-modal">
          <div className="modal-header">
            <div className="modal-header-left">
              {props.title ? props.title : "Bulk Upload Users"}
            </div>
            <div className="modal-header-right">
              <CloseOutlinedIcon
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="modal-body file-upload-body">
            <div className="text-center">
              <img src="/images/export.png" alt="export" />
              <div className="file-upload">
                <input
                  ref={aRef}
                  className="file-upload-input"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileChange}
                  data-testid="file-upload-input"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p className="p-mass-upload-content-margin">
                Click the above icon to browse and select the spreadsheet or
                drag & drop the spreadsheet matching the files you have
                uploaded.File size should be below 5MB. The only file types
                accepted are .xls or .xlsx.
              </p>
              <div>
                <h6 className="file-name uploaded-file text-align-center-upload-modal">
                  {file?.name ? "File Name :  " + file.name : ""}
                </h6>
              </div>
            </div>
          </div>
          <div
            className={`${
              props.from === "mass-upload-content" && "mass-upload-content"
            } modal-footer my-modal-footer`}
          >
            <p>
              You may also download our formatted template to enter the required
              information on the items being uploaded.
            </p>
            <div
              className="modal-switch"
              style={{
                marginBottom: props.from === "mass-upload-content" && "10px",
              }}
            >
              {props.from === "mass-upload-content" &&
                "Download new spreadsheet template "}
              <Link data-testid="downloadTemp" onClick={downloadTemplate}>
                {props.from === "mass-upload-content"
                  ? "HERE."
                  : "Download Template"}
              </Link>
            </div>
            <div modal-footer>
              <Button
                variant="outlined"
                className="action-button"
                onClick={() => {
                  handleClose();
                  setFile(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="action-button"
                onClick={handleUpload}
                disabled={!file || result}
              >
                Upload Matching Spreadsheet
              </Button>
            </div>
          </div>
          {result && file && (
            <div className="modal-body tabular-body">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>TOTAL</TableCell>
                    <TableCell>SUCCESS</TableCell>
                    <TableCell>ERROR</TableCell>
                    <TableCell>DOWNLOAD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="1">
                    <TableCell>{result.totalRecord}</TableCell>
                    <TableCell>{result.totalSuccess}</TableCell>
                    <TableCell>{result.totalError}</TableCell>
                    <TableCell>
                      {result.totalRecord !== result.totalSuccess && (
                        <Link data-testid="download" onClick={downloadHandler}>
                          Download
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UploadUserModal;

/*istanbul ignore next */
async function performNextStep(
  subExistsError,
  updatedSub,
  props,
  handleCloseSubs
) {
  if (subExistsError) {
    toast.error("Subscriber already associated.");
  } else {
    await makeUpdateRequest(updatedSub, props, handleCloseSubs);
  }
}

/*istanbul ignore next */
function checkAddSub(auth, updatedSub) {
  let subExistsError = false;
  switch (auth.getRole()) {
    case SystemUsersEnum.ENTERPRISE:
      if (updatedSub.enterpriseIds?.includes(auth.getCurrentUser()?.userId)) {
        subExistsError = true;
      } else {
        updatedSub.enterpriseIds = [
          ...updatedSub.enterpriseIds,
          auth.getCurrentUser()?.userId,
        ];
      }
      break;
    case SystemUsersEnum.DISTRIBUTOR:
      if (updatedSub.distributorIds?.includes(auth.getCurrentUser()?.userId)) {
        subExistsError = true;
      } else {
        updatedSub.distributorIds = [
          ...updatedSub.distributorIds,
          auth.getCurrentUser()?.userId,
        ];
      }
      break;
    default:
      break;
  }
  return subExistsError;
}

/*istanbul ignore next */
function whenSubscriberNotExists(response, setOpenConfirmation, showLoader) {
  if (
    !response.responseStatus?.isSuccess &&
    response.responseStatus.code === 400
  ) {
    setOpenConfirmation(true);
    showLoader(false);
  } else {
    toast.error(response.responseStatus?.error);
    showLoader(false);
  }
}

/*istanbul ignore next */
async function makeUpdateRequest(updatedSub, props, handleCloseSubs) {
  const response3 = await ApiServices.httpPut(SUBSCRIBER, updatedSub);
  if (response3.responseStatus?.isSuccess) {
    toast.success("Subscriber associated successfully");
    props.distributorsDetails();
    handleCloseSubs();
  } else {
    toast.error(response3.responseStatus?.error);
  }
}

/*istanbul ignore next */
async function commonUpload(showLoader, props, file, aRef, setResult) {
  try {
    showLoader(true);
    const formData = new FormData();
    formData.append("UserId", props.userId);
    formData.append("File", file);
    formData.append("FileName", file.name);
    response1 = await ApiServices.httpPostFile(
      DISTRIBUTOR_INTERNAL_USER_BULK_UPLOAD,
      formData
    );

    showLoader(false);
    if (response1.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response1.result);

      if (response1.result?.totalSuccess > 0) {
        props.onBulkUpload();
      }
    } else {
      toast.error(response1.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}

/*istanbul ignore next */
async function contentManageSubscribersUpload(
  showLoader,
  file,
  aRef,
  setResult,
  props,
  history
) {
  try {
    showLoader(true);
    let ids = GetAllSelectedIds("contentId");
    const formData = new FormData();
    for (let i = 0; i < ids.length; i++) {
      formData.append("ContentIds", ids[i]);
    }
    formData.append(
      "IsSubscriberByDist",
      !history.location.pathname.includes("enterprisegroup")
    );
    formData.append("UserId", props.userId);
    formData.append("File", file);
    response1 = await ApiServices.httpPostFile(
      BULK_UPLOAD_CONTENT_SUBSCRIBERS,
      formData
    );

    showLoader(false);
    if (response1.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response1.result);

      if (response1.result?.totalSuccess > 0) {
        //to refresh just using apply filters with old data
        props.setRefresh(props.contentQuery);
      }
    } else {
      toast.error(response1.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}

/*istanbul ignore next */
async function subscriberUpload(showLoader, file, aRef, setResult, props) {
  try {
    showLoader(true);
    const formData = new FormData();
    formData.append("File", file);
    formData.append("FileName", file.name);
    const response = await ApiServices.httpPostFile(
      SUBSCRIBER_BULK_UPLOAD,
      formData
    );
    showLoader(false);
    if (response.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response.result);

      if (response.result?.totalSuccess > 0) {
        props.distributorsDetails();
      }
    } else {
      toast.error(response.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}
