import React, { useState } from "react";
import { useParams } from "react-router";
import {
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Tooltip,
  Modal,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import PublicApiServices from "../../services/PublicApiServices";
import "./SignInWidget.css";
import {
  SHARE_ACTION,
  SHARE_Download_XFDL_TO_PDF,
  SHARE_VALIDATE,
  SHARE_VALIDATE_PASSWORD,
} from "../../constants/api-constants";
import { useLoading } from "../../hooks/useLoader";
import XFDLViewer from "../../common/XFDLViewer/XFDLViewer";
import ConfirmationWithReason from "../../common/ConfirmationWithReason/ConfirmationWithReason";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { AudioPreview } from "../../common/AudioPreview/AudioPreview";
import { typeOfContentOptions } from "../../screens/Distributors/constants";
import DocumentViewer from "../../common/DocumentViewer/DocumentViewer";
import { VideoPreview } from "../../common/VideoPreview/VideoPreview";
import Group2252459 from "../../common/Modal/Distributor/Group2252459.png";
import { execeptionMessage } from "../../constants/common";
import "./Share.css";

function Share(props) {
  const { id } = useParams();
  const { showLoader } = useLoading();
  const [linkValidated, setLinkValidated] = useState(false);
  const [linkValidationError, setLinkValidationError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState(null);
  const [result, setResult] = useState(null);
  const [resultList, setResultList] = useState([]);
  const [docIndex, setDocIndex] = useState(null);
  const [shareDetails, setShareDetails] = useState(null);
  const [folderName, setFolderName] = useState(null);
  const [downloadPDFPathForXFDL, setDownloadPDFPathForXFDL] = useState(null);
  const [xfdlCurrentJson, setXfdlCurrentJson] = useState(null);
  const [token, setToken] = useState(null);
  const [isInValid, setIsInValid] = useState(true);
  const [showAdditionalNotes, setShowAdditionalNotes] = useState(false);
  const [showAllAdditionalNotes, setShowAllAdditionalNotes] = useState(false);
  const [allAdditionalNotes, setAllAdditionalNotes] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const allowedContentTypes = [4, 5, 6, 1, 8, 9, 3];
  const [totalRemainingMandatoryFields, setTotalRemainingMandatoryFields] =
    useState(0);

  const ShareTypeEnum = {
    PDF: 1,
    INCHANTED: 2,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 616,
    bgcolor: "#FFFFFF",
    boxShadow: 24,
    p: 4,
  };

  const validateLink = async () => {
    try {
      showLoader(true);
      setLinkValidationError("");
      const response = await PublicApiServices.httpGet(
        `${SHARE_VALIDATE}/${id}`
      );
      if (response.responseStatus.isSuccess) {
        setLinkValidated(true);
      } else {
        setLinkValidationError(response.responseStatus.error);
      }
    } catch (e) {
      setLinkValidationError(execeptionMessage);
      toast.error(execeptionMessage);
    } finally {
      showLoader(false);
    }
  };

  const onAuthenticate = async () => {
    try {
      showLoader(true);
      const response = await PublicApiServices.httpGet(
        `${SHARE_VALIDATE}/${id}`
      );
      if (response.responseStatus.isSuccess) {
        await authenticateSuccess();
      } else {
        showLoader(false);
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error(execeptionMessage);
    }
  };

  const downloadDocument = async () => {
    try {
      if (xfdlCurrentJson && shareDetails === ShareTypeEnum.INCHANTED) {
        showLoader(true);
        let payload = {
          passcode: password,
          requestItems: resultList.map((item) => {
            return {
              contentId: item.contentId,
              shareContentId: item.shareContentId,
              formData: item.formData,
            };
          }),
          token: token,
        };
        const response = await PublicApiServices.httpPost(
          SHARE_Download_XFDL_TO_PDF,
          payload
        );
        if (response?.responseStatus?.isSuccess) {
          performDownload(response.result);
        } else {
          toast.error(response.responseStatus.error);
        }
        showLoader(false);
      } else {
        const payload = {
          code: id,
          password: password,
        };
        showLoader(true);
        const res = await PublicApiServices.httpPost(
          SHARE_VALIDATE_PASSWORD,
          payload
        );

        if (res.responseStatus.isSuccess) {
          showLoader(false);
          let downloadPath = res.result.share.mergePdfPath
            ? res.result.share.mergePdfPath
            : res.result.share.shareContents.find(
                (x) => x.shareContentId === docIndex
              )?.gcpFilePath;
          performDownload(downloadPath);
        } else {
          showLoader(false);
          toast.error(res.responseStatus.error);
        }
      }
    } catch (e) {
      showLoader(false);
      toast.error(execeptionMessage);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && password) {
      onAuthenticate();
    }
  };

  const saveOrSubmit = (isSubmit) => {
    if (isSubmit) {
      let notes = resultList
        .filter((x) => x.additionalNotes)
        ?.map((item) => {
          return {
            additionalNotes: item.additionalNotes,
            shareContentId: Number(item.shareContentId),
            uniqueId: Math.floor(Math.random() * 1000),
          };
        });
      setAllAdditionalNotes(notes);
      setShowAllAdditionalNotes(true);
    } else {
      apiHandler(isSubmit);
    }
  };

  const apiHandler = async (isSubmit, allAdditionalNotesLocal) => {
    try {
      showLoader(true);
      let payload = {
        isSubmit: isSubmit,
        shareContentFormData: resultList.map((item) => {
          return {
            contentId: item.contentId,
            additionalNotes: allAdditionalNotesLocal.find(
              (x) => x.shareContentId === item.shareContentId
            )?.additionalNotes,
            shareContentId: item.shareContentId,
            usersFormData: item.formData,
          };
        }),
        token: token,
      };
      const response = await PublicApiServices.httpPost(SHARE_ACTION, payload);
      if (response?.responseStatus?.isSuccess) {
        toast.success(response.responseStatus.description);
        setShowAllAdditionalNotes(false);
      } else {
        toast.error(response.responseStatus.error);
      }
      showLoader(false);
      if (isSubmit) {
        setIsSubmitted(true);
        setIsInValid(true);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  const changeHandler = (event, itemName, isRequired) => {
    let results = [...resultList];
    results.forEach((item) => {
      let value = item.formData?.filter((el) => el.key === itemName);
      if (value.length > 0) {
        item.formData.forEach((item) => {
          if (item.key === itemName) {
            item.value = event.target.value;
          }
        });
      } else {
        item.formData = [
          ...item.formData,
          { key: itemName, value: event.target.value },
        ];
      }
    });
    setResultList(results);
    checkRequiredFields();
  };

  const changeNotesHandler = (additionalNotes) => {
    let results = [...resultList];
    results.forEach((item) => {
      if (item.shareContentId === docIndex) {
        item.additionalNotes = additionalNotes;
      }
    });
    setResultList(results);
    setShowAdditionalNotes(false);
  };

  const changeSubmitNotesHandler = (
    uniqueId,
    additionalNotes = "",
    newId = 0
  ) => {
    let results = [...allAdditionalNotes];
    results.forEach((item) => {
      if (item.uniqueId === uniqueId) {
        item.additionalNotes = additionalNotes;
        item.shareContentId = Number(newId);
      }
    });
    setAllAdditionalNotes(results);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const checkBoxHandler = (event, itemName, isRequired) => {
    let results = [...resultList];
    results.forEach((item) => {
      let value = item.formData?.filter((el) => el.key === itemName);
      if (value.length > 0) {
        item.formData.forEach((item) => {
          if (item.key === itemName) {
            item.value = event.target.checked ? "true" : "false";
          }
        });
      } else {
        item.formData = [
          ...item.formData,
          { key: itemName, value: event.target.checked ? "true" : "false" },
        ];
      }
    });

    setResultList(results);
    checkRequiredFields();
  };

  const checkRequiredFields = (resultListInput = null) => {
    let results = resultListInput ? [...resultListInput] : resultList;
    let invalid = results.length < 1;
    let totolManReqFieldCount = 0;
    results
      .filter((x) => x.typeOfContent === 2)
      .forEach((item) => {
        if (item.xfdlJson?.Canvas?.length > 0) {
          item.requiredFeilds = checkIfRequiredFieldExist(
            item.xfdlJson,
            item.requiredFeilds
          );
        }
        if (item.requiredFeilds?.length > item.formData?.length) {
          invalid = true;
        }
        item.MandatoryReqFieldCount = 0;
        for (let i = 0; i < item.requiredFeilds.length; i++) {
          const reqField = item.requiredFeilds[i];
          const matchingField = item.formData.find(
            (field) => field.key === reqField.key
          );
          if (
            !matchingField ||
            matchingField.value.trim() === "" ||
            matchingField.value === "false"
          ) {
            invalid = true; // Required field is empty
            item.MandatoryReqFieldCount++;
          }
        }
        totolManReqFieldCount += item.MandatoryReqFieldCount;
      });
    // All required fields have been checked values
    setTotalRemainingMandatoryFields(totolManReqFieldCount);
    setIsInValid(invalid);
  };

  useState(() => {
    validateLink();
  });

  // Function to check if a field with a given name exists in a canvas section
  const fieldExistsInSection = (section, requriredFieldsValue) => {
    let fields = [];
    for (const requiredField of requriredFieldsValue) {
      if (
        section?.TextBoxes.some((field) => field.Name === requiredField.key)
      ) {
        fields.push(requiredField);
      }
      if (
        section?.CheckBoxes.some((field) => field.Name === requiredField.key)
      ) {
        fields.push(requiredField);
      }
    }
    return fields;
  };

  const checkIfRequiredFieldExist = (xfdlJsonValue, requriredFieldsValue) => {
    let totalPages = xfdlJsonValue.TotalPages;
    let newRequiredFields = new Set();

    for (let i = 0; i <= totalPages; i++) {
      let newFieldValue = fieldExistsInSection(
        xfdlJsonValue.Canvas[i],
        requriredFieldsValue
      );
      if (newFieldValue?.length > 0) {
        for (const field of newFieldValue) {
          newRequiredFields.add(field);
        }
      }
    }
    const array = Array.from(newRequiredFields);
    return array;
  };

  return (
    <>
      {linkValidated ? (
        <div className="myContainer-link" style={{ background: "#fff" }}>
          {isAuthenticated &&
            [ShareTypeEnum.INCHANTED, ShareTypeEnum.PDF].includes(
              shareDetails
            ) &&
            loadPage()}
          {!isAuthenticated && (
            <Container maxWidth="md" className="main-grid-container">
              <div className="cus-align-div">
                <Typography className="type-align">
                  Enter Your Password
                </Typography>
                <Grid item xs={12} m={2} className="cus-grid-align">
                  <TextField
                    autoFocus
                    className="cus-grid-align"
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePassword}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon
                                data-testid="hidePassword"
                                fontSize="small"
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                data-testid="showPassword"
                                fontSize="small"
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} m={2} className="cus-grid-align">
                  <Button
                    variant="contained"
                    disabled={!password}
                    onClick={() => {
                      onAuthenticate();
                    }}
                    className="cus-submit-align"
                  >
                    Submit
                  </Button>
                </Grid>
              </div>
            </Container>
          )}
          {showAdditionalNotes && (
            <ConfirmationWithReason
              open={showAdditionalNotes}
              handleClose={() => {
                setShowAdditionalNotes(false);
              }}
              title={"Additional Notes"}
              cancelButtonLabel="Cancel"
              confirmButtonLabel="Add"
              showReason={true}
              description={
                resultList.find((x) => x.shareContentId === docIndex)
                  ?.contentName
              }
              currentReason={
                resultList.find((x) => x.shareContentId === docIndex)
                  ?.additionalNotes
              }
              onConfirm={async (e, additionalNotes) => {
                changeNotesHandler(additionalNotes);
              }}
              onCancel={() => {
                setShowAdditionalNotes(false);
              }}
              placeholder={"Enter Notes"}
            />
          )}
          {showAllAdditionalNotes && (
            <Modal
              open={showAllAdditionalNotes}
              handleClose={() => {
                setShowAllAdditionalNotes(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="confirmation-with-reason"
            >
              <Box sx={style}>
                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={6}>
                    <Typography id="modal-modal-title" sx={{ mb: 2 }}>
                      Notes Summary
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className="add-association-share top-right-actions"
                  >
                    {allAdditionalNotes?.length < resultList.length && (
                      <div
                        role="button"
                        name="add-icon"
                        title="add-icon"
                        className="add-itemz-font"
                        data-testid="AddAdditionalNote"
                        onClick={(e) => {
                          setAllAdditionalNotes((addNotes) => {
                            return [
                              ...addNotes,
                              {
                                additionalNotes: "",
                                shareContentId: 0,
                                uniqueId: Math.floor(Math.random() * 1000),
                              },
                            ];
                          });
                        }}
                      >
                        <h5>
                          <span>+ Add Additional Note</span>
                        </h5>
                      </div>
                    )}
                  </Grid>
                </Grid>

                {allAdditionalNotes.map((item, index) => {
                  return (
                    <div
                      key={item.uniqueId}
                      className="reason-div"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <select
                            id="selectSharedForm"
                            name="selectSharedForm"
                            value={item.shareContentId}
                            onChange={(e) => {
                              changeSubmitNotesHandler(
                                item.uniqueId,
                                item.additionalNotes,
                                e.target.value
                              );
                            }}
                            className="form-control form-select"
                          >
                            <option
                              selected="true"
                              disabled="true"
                              value={0}
                            ></option>
                            {resultList
                              .filter(
                                (x) =>
                                  item.shareContentId === x.shareContentId ||
                                  !allAdditionalNotes.some(
                                    (y) => y.shareContentId === x.shareContentId
                                  )
                              )
                              ?.map((res) => {
                                return (
                                  <option
                                    key={res.contentName}
                                    value={res.shareContentId}
                                  >
                                    {res.contentName}
                                  </option>
                                );
                              })}
                          </select>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className="blue-field-section top-right-actions"
                        >
                          <IconButton
                            data-testid="btn-del"
                            aria-label="delete"
                            style={{ marginTop: "-10px" }}
                            color="primary"
                            onClick={() => {
                              setAllAdditionalNotes((addNotes) => {
                                return [
                                  ...addNotes.filter((x, i) => i !== index),
                                ];
                              });
                            }}
                          >
                            <img src={Group2252459} alt="Delete" height={35} />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <TextField
                        multiline
                        value={item.additionalNotes}
                        className="reason-div-textfield"
                        data-testid="reason_textbox"
                        rows={3}
                        onChange={(e) => {
                          changeSubmitNotesHandler(
                            item.uniqueId,
                            e.target.value,
                            item.shareContentId
                          );
                        }}
                        style={{ width: "100%", padding: 1 }}
                      />
                    </div>
                  );
                })}

                <div className="action-btn-div mt-4 action-popup-share">
                  <Button
                    className="action-button"
                    variant="outlined"
                    onClick={(e) => {
                      setShowAllAdditionalNotes(false);
                    }}
                    data-testid="cancel-action"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="action-button ml-4"
                    variant="contained"
                    disabled={allAdditionalNotes.some(
                      (x) => !x.shareContentId > 0
                    )}
                    onClick={(e) => {
                      apiHandler(true, allAdditionalNotes);
                    }}
                    data-testid="confirm-action"
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </Modal>
          )}
        </div>
      ) : (
        <div className="myContainer-link">
          <Container maxWidth="md" className="main-grid-container">
            <Grid container justifyContent="center" className="invalid-links">
              <Grid item xs={7} md={7} className="login-form ">
                <div className="signup-container invalid-link">
                  <Grid item xs={12} m={2}>
                    {linkValidationError}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );

  function performDownload(downloadPath) {
    if (downloadPath) {
      let newWin = window.open(downloadPath, "_blank");
      if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
        //POPUP BLOCKED
        toast.error(
          "To download the contents, kindly disable the pop-up blocker."
        );
      } else {
        toast.success("Document Downloaded Successfully");
      }
    } else {
      toast.error("There is no document to download");
    }
  }

  async function authenticateSuccess() {
    try {
      const payload = {
        code: id,
        password: password,
      };
      const res = await PublicApiServices.httpPost(
        SHARE_VALIDATE_PASSWORD,
        payload
      );

      if (res.responseStatus.isSuccess) {
        showLoader(false);
        if (
          res.result.share.shareContents &&
          res.result.share.shareContents.length > 0
        ) {
          setShareDetails(res.result.share.shareType);
          setFolderName(res.result.share.clientName);

          setDownloadPDFPathForXFDL(res.result.share.mergePdfPath);

          let allContent = res.result.share.shareContents.sort((a, b) =>
            a.shareContentId > b.shareContentId ? 1 : -1
          );
          allContent.forEach((item) => {
            item.xfdlJson = JSON.parse(item.xfdlJson);
          });
          handleContentLevelFirst(allContent);
          let currentContent = allContent[0];
          setDocIndex(currentContent.shareContentId);
          initializeFormDetails(
            currentContent,
            res.result.share.shareType,
            res.result.share.mergePdfPath
          );
        }
        setIsAuthenticated(true);
        setToken(res.result.token);
      } else {
        showLoader(false);
        toast.error(res.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error(execeptionMessage);
    }
  }

  function initializeFormDetails(
    formDetails,
    shareType = "",
    mergePdfPath = downloadPDFPathForXFDL
  ) {
    setResult(formDetails);
    setXfdlCurrentJson(formDetails.xfdlJson);
    // For PDF & JPG (1, 3) Need to use fileStream as base64 string
    handleContentLevelSecond(formDetails, shareType, mergePdfPath);
  }

  function handleContentLevelSecond(selectedShare, shareType, mergePdfPath) {
    if (
      selectedShare.typeOfContent === 1 ||
      selectedShare.typeOfContent === 8 ||
      selectedShare.typeOfContent === 9 ||
      selectedShare.typeOfContent === 3 ||
      selectedShare.typeOfContent === 7 || // For Audio file
      selectedShare.typeOfContent === 10 // For Video file
    ) {
      if (selectedShare.fileStream) {
        setSelectedDocument([
          {
            uri: selectedShare.fileStream,
            fileName: selectedShare.fileName,
            fileType: typeOfContentOptions?.find(
              (item) => item.value === selectedShare.typeOfContent
            )?.fileType,
          },
        ]);
      }
    } else {
      handleDocumentFilesInfo(selectedShare, shareType, mergePdfPath);
    }
  }

  function handleDocumentFilesInfo(selectedShare, shareType, mergePdfPath) {
    if (
      selectedShare.typeOfContent === 4 ||
      selectedShare.typeOfContent === 5 ||
      selectedShare.typeOfContent === 6 ||
      (selectedShare.typeOfContent === 2 && shareType === ShareTypeEnum.PDF)
    ) {
      if (selectedShare.typeOfContent === 2 && mergePdfPath) {
        setSelectedDocument([
          {
            uri: mergePdfPath,
            fileName: selectedShare.filePath,
            fileType: "pdf",
          },
        ]);
      } else {
        if (selectedShare.gcpFilePath) {
          setSelectedDocument([
            {
              uri: selectedShare.gcpFilePath,
              fileName: selectedShare.filePath,
              fileType: typeOfContentOptions?.find(
                (item) => item.value === selectedShare.typeOfContent
              )?.fileType,
            },
          ]);
        } else {
          setSelectedDocument([]);
        }
      }
    }
  }

  function handleContentLevelFirst(results) {
    results.forEach((selectedShare) => {
      selectedShare.additionalNotes =
        selectedShare.additionalNotesHistory?.length > 0
          ? selectedShare.additionalNotesHistory[0].notes
          : "";
      if (selectedShare.usersFormData?.length > 0) {
        const uniqueKeysMap = new Map();
        for (const item of selectedShare.usersFormData) {
          uniqueKeysMap.set(item.key, item.value);
        }
        const filteredFormData = selectedShare.formData.filter(
          (item) => !uniqueKeysMap.has(item.key)
        );
        const mergedFormData = filteredFormData.concat(
          selectedShare.usersFormData
        );
        selectedShare.formData = mergedFormData;
      }
    });
    setResultList(results);
    checkRequiredFields(results);
  }

  function loadPage() {
    return (
      <Container
        maxWidth="md"
        className="width-container-share"
        style={{ width: `${Number(xfdlCurrentJson?.FormViewSize) + 30}px` }}
      >
        {result.typeOfContent === 11 && result?.webLink ? (
          <Grid item xs={12} pt={2}>
            <Typography className="login-para" varient="h5" paragraph>
              Kindly click on the link below to open the document.
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} pt={2} className="Grid-div-share">
            <div className="btns-share-xfdl-down">
              <Button
                variant="contained"
                onClick={downloadDocument}
                className="button-mrgin-share"
              >
                {resultList.length > 1 ? "Download All" : "Download"}
              </Button>
              {xfdlCurrentJson && shareDetails === ShareTypeEnum.INCHANTED && (
                <Button
                  disabled={isSubmitted}
                  variant="contained"
                  onClick={() => {
                    saveOrSubmit(false);
                  }}
                  className="button-mrgin-share"
                >
                  {resultList.length > 1 ? "Save All" : "Save"}
                </Button>
              )}
              {xfdlCurrentJson && shareDetails === ShareTypeEnum.INCHANTED && (
                <Button
                  disabled={isInValid || isSubmitted}
                  variant="contained"
                  onClick={() => {
                    saveOrSubmit(true);
                  }}
                  className="button-mrgin-share"
                >
                  {resultList.length > 1 ? "Submit All" : "Submit"}
                </Button>
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12} m={2} style={{ margin: "5px 0" }}>
          {result.typeOfContent === 7 && (
            <AudioPreview
              selectedDocument={selectedDocument}
              fileName={result.fileName}
            />
          )}
          {result.typeOfContent === 10 && (
            <VideoPreview
              selectedDocument={selectedDocument}
              fileName={result.fileName}
            />
          )}
          {result.typeOfContent === 11 && result?.webLink && (
            <Tooltip placement="bottom-start" title={result.webLink}>
              <Grid item xl={12} xs={12} m={2}>
                <Link href={result.webLink} target="_blank">
                  Web link
                </Link>
              </Grid>
            </Tooltip>
          )}
          {xfdlCurrentJson && shareDetails === ShareTypeEnum.INCHANTED && (
            <>
              {resultList && (
                <Grid container className="select-share-xfdl" item xs={12}>
                  {
                    <Grid item xs={12}>
                      <div>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Typography className="agency-name-align">
                            Agency :
                          </Typography>
                          <label htmlFor="sharedForm">
                            <b>
                              {
                                resultList
                                  .find((x) => x.shareContentId === docIndex)
                                  ?.formData?.find(
                                    (y) => y.key === "Producer_FullName_A"
                                  )?.value
                              }
                            </b>
                          </label>
                        </span>
                      </div>
                    </Grid>
                  }
                  {[1, 2].includes(
                    resultList.find((x) => x.shareContentId === docIndex)
                      ?.typeOfContent
                  ) && (
                    <Grid item xs={12}>
                      <div>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Typography className="agency-name-align">
                            Form Number :
                          </Typography>
                          <label htmlFor="sharedForm">
                            <b>
                              {
                                resultList.find(
                                  (x) => x.shareContentId === docIndex
                                )?.formNumber
                              }
                            </b>
                          </label>
                        </span>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <label htmlFor="sharedForm">
                      <b>{folderName ?? "Forms"}</b>
                    </label>
                    <label htmlFor="sharedForm" style={{ color: "red" }}>
                      Total Mandatory fields left:{" "}
                      {totalRemainingMandatoryFields}
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <select
                      id="selectSharedForm"
                      name="selectSharedForm"
                      value={docIndex}
                      onChange={(e) => {
                        setDocIndex(Number(e.target.value));
                        initializeFormDetails(
                          resultList.find(
                            (x) => x.shareContentId === Number(e.target.value)
                          ),
                          shareDetails
                        );
                      }}
                      className="form-control form-select"
                    >
                      {resultList.map((res) => {
                        return (
                          <option
                            key={res.contentName}
                            value={res.shareContentId}
                          >
                            {res.contentName}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={6}>
                  <label style={{ color: "red" }}>
                    Mandatory fields left:{" "}
                    {
                      resultList.find((x) => x.shareContentId === docIndex)
                        ?.MandatoryReqFieldCount
                    }
                  </label>
                </Grid>
                <Grid item xs={6} className="top-right-actions">
                  <div
                    role="button"
                    name="AddNote"
                    className="add-itemz-font"
                    data-testid="AddNote"
                    onClick={() => {
                      setShowAdditionalNotes(true);
                    }}
                  >
                    <h5>
                      <span>+ Add Note</span>
                    </h5>
                  </div>
                </Grid>
              </Grid>

              <XFDLViewer
                document={xfdlCurrentJson}
                fileName={result?.contentName}
                formData={
                  resultList.find((x) => x.shareContentId === docIndex)
                    ?.formData
                }
                requiredFields={
                  resultList.find((x) => x.shareContentId === docIndex)
                    ?.requiredFeilds
                }
                saveOrSubmit={saveOrSubmit}
                from="share"
                changeHandler={changeHandler}
                checkBoxHandler={checkBoxHandler}
                isValid={isInValid}
                isSubmitted={isSubmitted}
                checkRequiredFields={checkRequiredFields}
              />
            </>
          )}
          {shareDetails === ShareTypeEnum.PDF && (
            <div className="pdf-div-foldername">
              <label htmlFor="sharedForm" className="pdf-div-foldername-label">
                <b>{folderName ?? "Forms"}</b>
              </label>
            </div>
          )}
          {(allowedContentTypes.includes(result.typeOfContent) ||
            (xfdlCurrentJson && shareDetails === ShareTypeEnum.PDF)) &&
            selectedDocument.length > 0 && (
              <DocumentViewer
                disableHeader={true}
                tempId={Math.floor(Math.random() * 1000 + 1)} // Just for rerendering
                documents={selectedDocument}
                config={{
                  noRenderer: {
                    overrideComponent: <div>No Document uploaded</div>,
                  },
                }}
              />
            )}
        </Grid>
      </Container>
    );
  }
}

export default Share;
