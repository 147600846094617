import * as Yup from "yup";
import { REGULAR_EXPRESSION } from "../../constants/regular-expressions";
import moment from "moment";
import { distributorsubscriptionPlanDomainsSchema } from "./ValidationSchema";

export const baseSubscriptionSchema = Yup.object().shape({
  ...distributorsubscriptionPlanDomainsSchema,
  effectiveFromDate: Yup.date().when("contentSubscriptionId", {
    is: (contentSubscriptionId) => contentSubscriptionId > 0,
    then: Yup.date()
      .nullable()
      .default(null)
      .required("")
      .typeError("Please input the date in the correct format"),
    otherwise: Yup.date()
      .nullable()
      .default(null)
      .required("")
      .min(
        moment(new Date().setHours(0, 0, 0, 0)),
        "Date must be a present or future date"
      )
      .typeError("Please input the date in the correct format"),
  }),

  effectiveToDate: Yup.date().when(
    ["contentSubscriptionId", "savedEffectiveToDate", "effectiveFromDate"],
    {
      is: (contentSubscriptionId, savedEffectiveToDate) =>
        !contentSubscriptionId > 0 || (savedEffectiveToDate ? false : true),
      then: Yup.date()
        .nullable()
        .default(null)
        .when(["effectiveFromDate", "contentSubscriptionId"], {
          is: (effectiveFromDate, contentSubscriptionId) =>
            effectiveFromDate && !contentSubscriptionId > 0,
          then: Yup.date()
            .nullable()
            .default(null)
            .min(
              Yup.ref("effectiveFromDate"),
              "Date must be greater than or equal to the Effective From date."
            )
            .typeError("Please input the date in the correct format"),
          otherwise: Yup.date()
            .nullable()
            .default(null)
            .min(
              moment(new Date().setHours(0, 0, 0, 0)),
              "Date must be a present or future date"
            )
            .typeError("Please input the date in the correct format"),
        })
        .typeError("Please input the date in the correct format"),
      otherwise: Yup.date()
        .nullable()
        .default(null)
        .typeError("Please input the date in the correct format"),
    }
  ),
});

export const ContentSchema = Yup.object().shape({
  typeOfContent: Yup.string().required("Please Select"),
  name: Yup.string().required("").min(2, "Too Short!").max(150, "Too Long!"),
  latestVersion: Yup.string()
    .required("")
    .min(1, "Too Short!")
    .max(25, "Too Long!"),
  webLink: Yup.string().when("typeOfContent", {
    is: "11",
    then: Yup.string()
      .required("")
      .matches(REGULAR_EXPRESSION.URL, "Incorrect Format"),
    otherwise: Yup.string()
      .matches(REGULAR_EXPRESSION.URL, "Incorrect Format")
      .nullable(true),
  }),
  formNumber: Yup.string().when("typeOfContent", {
    is: (typeOfContent) => ["2", "1"].includes(typeOfContent),
    then: Yup.string().required("").nullable().max(150, "Too Long!"),
    otherwise: Yup.string().max(150, "Too Long!").nullable(),
  }),
  subscriptions: Yup.array().of(baseSubscriptionSchema),
});
