import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import TabPanel from "../../common/TabPanel/TabPanel";
import CreateDistributorModal from "../../common/Modal/Distributor/CreateDistributorModal";
import CreateEnterpriseModal from "../../common/Modal/Enterprise/CreateEnterpriseModal";
import "../Distributors/DistributorProfile.css";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { a11yProps } from "../../common/TabPanel/a11yProps";
import InternalUsers from "./InternalUsers/InternalUsers";
import AddEditInternalUserModal from "../../common/Modal/InternalUsers/AddEditInternalUserModal";
import { toast } from "react-toastify";
import { usePageMode } from "../../hooks/usePageMode";
import { useAuth } from "../../hooks/useAuth";
import { getServiceInstance } from "../../services/UserServices/UserService";
import { useLoading } from "../../hooks/useLoader";
import { useMasterData } from "../../hooks/useMasterData";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import {
  GetAllSelectedIds,
  ClearSelectionData,
  GetSelectedColumns,
  getSelectedCols,
} from "../../utilities/SelectionFilter";
import Content from "./Content/Content";
import Catalog from "./Catalog/Catalog";
import { MODE, TABS } from "../../constants/common";
import { FILTER_OPTION } from "../../constants/filterOptions";
import { useDistributorProfileContext } from "./DistributorProfile.context";
import { useContentContext } from "./Content/Content.context";
import { useCatalogContext } from "./Catalog/Catalog.context";
import {
  PermissionService,
  PermissionEnum,
  getModuleId,
  PermissionServiceChild,
  childModulePermissions,
  enabledTabs,
} from "../../constants/permissionService";
import { DocumentType } from "./constants";
import Bundle from "./Bundle/Bundle";
import { useBundleContext } from "./Bundle/Bundle.context";
import {
  SystemModulesEnum,
  SystemUsersEnum,
} from "../../constants/SystemUsers";
import BasicInfo from "../../common/BasicInformation/BasicInfo";

const DistributorProfile = (props) => {
  const { bulkUploadTemplate } = props.routeData;
  const [formData, setFormData] = useState({
    // createdOn: null,
    systemGeneratedId: "",
    userTypeId: props.routeData?.userTypeId,
    firstName: "",
    lastName: "",
    brandName: "",
    primaryEmail: "",
    secondaryEmail: "",
    phoneNumber: "",
    secondaryPhoneNumber: "",
    distributorType: [],
    areas: [],
    carrierRepresents: [],
    carriers: [],
    industryGroups: [],
    technologies: [],
    annualRevenue: "",
    billingContact: "",
    billingContactLastName: "",
    businessYear: "",
    websiteUrl: "",
    numberOfEmployees: "",
    numberOfOwners: "",
    producers: "",
    userTypeName: "",
    billingPhoneNumber: "",
    billingAddressLine1: "",
    billingAddressLine2: "",
    businessName: "",
    taxID: null,
    zip: "",
    countryId: 1,
    city: "",
    state: "0",
    mobile: "",
    mobile1: "",
    fax: "",
    fax1: "",
    status: 1,
    egSubscriptions: [],
    // parentUserId: { label: "", value: 0 },
    parentUser: {
      parentUserId: 0,
      permissions: [],
    },
    associateDistributors: [],
    roles: [],
    distributorSubscriptionDomains: [],
    dirty: false,
    enterpriseIds: [],
    distributorIds: [],
    userAddOns: [],
    logoGcpPath: "",
    logoGcpFileUrl: "",
    signUpApproval: null,
  });
  const masterDataContext = useMasterData();
  const distributorProfileContext = useDistributorProfileContext();
  const contentContext = useContentContext();
  const catalogContext = useCatalogContext();
  const bundleContext = useBundleContext();
  const location = useLocation();
  const { id, tabId } = useParams();
  const [selectedTab, setSelectedTab] = useState(TABS.BASIC);
  const [selectedSubTab, setSelectedSubTab] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createUserView, setCreateUserView] = useState(false);
  const [userRoles, setUserRoles] = React.useState({});
  const [internalFilterOptions, setInternalFilterOptions] = React.useState(
    FILTER_OPTION.INTERNALUSER_LIST
  );
  const [contentFilterOptions, setContentFilterOptions] = React.useState(
    FILTER_OPTION.DISTRIBUTOR_CONTENT_LIST
  );
  const [catalogFilterOptions, setCatalogFilterOptions] = React.useState(
    FILTER_OPTION.DISTRIBUTOR_CATALOG_LIST
  );
  const [bundleFilterOptions, setBundleFilterOptions] = React.useState(
    FILTER_OPTION.DISTRIBUTOR_BUNDLE_LIST
  );
  const [typecontentListOptions, settypecontentListOptions] = React.useState(
    FILTER_OPTION.TYPE_OF_CONTENT_LIST
  );
  const [refreshData, setRefreshData] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loaded, setLoaded] = useState(false);
  const [contentMode, setContentMode] = useState(contentContext.contentMode);
  const [contentId, setContentId] = useState(contentContext.contentId);
  const [catalogMode, setCatalogMode] = useState(catalogContext.catalogMode);
  const [catalogId, setCatalogId] = useState(catalogContext.catalogId);
  const [bundleMode, setBundleMode] = useState(bundleContext.bundleMode);
  const [bundleId, setBundleId] = useState(bundleContext.bundleId);
  const [tabPermissions, setTabPermissions] = useState([]);
  const [parentPermissions, setParentPermissions] = useState([]);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [
    tableColumnModalVisibilityInternal,
    setTableColumnModalVisibilityInternal,
  ] = useState(false);

  const auth = useAuth();
  const { showLoader } = useLoading();

  const userService = getServiceInstance(props.routeData?.userTypeId);
  const roleId = auth.getRole();

  const setRefreshCallBack = (status) => {
    setRefreshData(status);
  };
  const handleCloseModalCallback = () => {
    setCreateUserView(false);
  };

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      userStatus: [0, 1],
      roles: [],
      selectedColumns: [],
    },
  });
  const initialContentQuery = {
    searchText: "",
    SortType: "desc",
    SortColumn: "isFavorite",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      lineOfBusinessList: [],
      typeOfFormList: [],
      selectedColumns: [],
      contentType: [],
      status: [],
      publishStatus: "",
      catalogType: [],
      bundleType: [],
      contentAssociationStatus: [],
    },
  };
  const [contentQuery, setContentQuery] = useState(initialContentQuery);

  const history = useHistory();
  const page = usePageMode();

  const permissions = PermissionService(
    getModuleId(props?.routeData?.userTypeId)
  );

  const allUsersExportPermission =
    (selectedTab === TABS.INTERNAL_USERS ||
      selectedTab === TABS.CONTENT ||
      selectedTab === TABS.CATALOG ||
      selectedTab === TABS.BUNDLE) &&
    (roleId === 1 ||
      roleId === 2 ||
      roleId === 3 ||
      roleId === 4 ||
      roleId === 5 ||
      roleId === 6);
  const addInternalUserFormData = {
    firstName: "",
    lastName: "",
    primaryEmail: "",
    secondaryEmail: "",
    phoneNumber: "",
    secondaryPhoneNumber: "",
    userTypeId: "",
    userId: "",
    systemGeneratedId: "",
    status: true,
  };

  const getSeletedSubModule = () => {
    const userPathRole = getPathRole(roleId, props);

    let selectedSubModule = switchByTab(selectedTab, userPathRole);

    return selectedSubModule;
  };

  useEffect(() => {
    const currentTabPermissions = PermissionServiceChild(
      getModuleId(props?.routeData?.userTypeId),
      getSeletedSubModule()
    );
    setTabPermissions(currentTabPermissions);
  }, [selectedTab]);

  useState(() => {
    setCurrentUser(auth.getCurrentUser());
  }, [auth.currentUser]);

  const getEditData = async (userId) => {
    if (userId > 0) {
      try {
        showLoader(true);
        let user = await userService.getUser(userId);
        showLoader(false);
        if (user && user.associateDistributors) {
          let retVal = user.associateDistributors.map((el) => {
            return {
              ...el,
              uid: Math.floor(Math.random() * 1000),
              distributorUserId: {
                value: el.distributorUserId,
                label: el.brandName || "",
              },
              associationId: el.associationId,
              frequencyType: el.frequencyType,
              isRecurring: el.isRecurring,
              isNewAssociation: el.isNewAssociation,
              expirationDate: el.expirationDate,
              distributorSubscriptionId: el.distributorSubscriptionId,
              associatonStatus: el.associatonStatus,
              requestId: el.requestId,
              enterpriseUserId: el.enterpriseUserId,
              subscriptionTypes: [],
              isStopped: el.isStopped,
              startDate: el.startDate,
              endDate: el.stoppedDate,
              status: el.status,
              isSubscriberAssociate: el.isSubscriberAssociate,
              isFutureSubscription: el.isFutureSubscription,
              subscriptionStatus: el.subscriptionStatus,
              distributorSubscriptionPlanId: el.distributorSubscriptionPlanId,
            };
          });
          user = {
            ...user,
            associateDistributors: [...retVal],
          };
        } else {
          user.isDirty = false;
        }

        if (user && user.distributorSubscriptionDomains) {
          let retVal = user.distributorSubscriptionDomains.map((el) => {
            return {
              ...el,
              uid: Math.floor(Math.random() * 1000),
              distributorSubscriptionId: el.distributorSubscriptionId,
              name: el.name,
              status: el.status,
              subscriptionPlan: el.subscriptionPlan?.map((el) => {
                return {
                  uniqueId: Math.floor(Math.random() * 1000),
                  baseValue: el.baseValue?.toFixed(2),
                  // isAssociated: el.isAssociated,
                  status: el.status,
                  distributorSubscriptionPlanId:
                    el.distributorSubscriptionPlanId,
                  distributorSubscriptionId: el.distributorSubscriptionId,
                  effectiveFromDate: el.effectiveFromDate,
                  effectiveToDate: el.effectiveToDate,
                  savedEffectiveToDate: el.effectiveToDate,
                  frequency: el.frequency,
                  isAssociated: el.isAssociated,
                };
              }),
              // isAssociated: el.isAssociated,
            };
          });
          user = {
            ...user,
            distributorSubscriptionDomains: [...retVal],
          };
        }
        setFormData(user);
      } catch (e) {
        showLoader(false);
      }
    }
  };
  const loadDropndownOption = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      showLoader(false);
      const bundleOptions = [...bundleFilterOptions];

      // LOB
      bundleOptions[2].options = response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          roleId: r.lineOfBusinessId,
          label: r.name,
        };
      });
      // Type of Form
      bundleOptions[6].options = response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          roleId: r.typeOfFormId,
          label: r.name,
        };
      });
      // Countries
      bundleOptions[1].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });

      // State
      bundleOptions[4].options = response.result.states.map((r) => {
        return {
          value: r.stateId,
          roleId: r.stateId,
          label: r.name,
        };
      });
      setBundleFilterOptions(bundleOptions);
      setParentPermissions(response.result.parentPermissions);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (!loaded) {
      loadDropndownOption();
      setLoaded(true);
    }
  }, []);

  const loadDropdownOption = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      showLoader(false);
      const catalogOptions = [...catalogFilterOptions];

      // LOB
      catalogOptions[2].options = response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          roleId: r.lineOfBusinessId,
          label: r.name,
        };
      });
      // Type of Form
      catalogOptions[6].options = response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          roleId: r.typeOfFormId,
          label: r.name,
        };
      });
      // Countries
      catalogOptions[1].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });

      // State
      catalogOptions[4].options = response.result.states.map((r) => {
        return {
          value: r.stateId,
          roleId: r.stateId,
          label: r.name,
        };
      });
      setCatalogFilterOptions(catalogOptions);
      setParentPermissions(response.result.parentPermissions);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (!loaded) {
      loadDropdownOption();
      setLoaded(true);
    }
  }, []);

  const loadDropdownlistOption = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      showLoader(false);
      const typecontentOptions = [...typecontentListOptions];

      // LOB
      typecontentOptions[4].options = response.result.lineOfBusiness.map(
        (r) => {
          return {
            value: r.lineOfBusinessId,
            roleId: r.lineOfBusinessId,
            label: r.name,
          };
        }
      );
      // Type of Form
      typecontentOptions[8].options = response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          roleId: r.typeOfFormId,
          label: r.name,
        };
      });
      // Countries
      typecontentOptions[3].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });

      // State
      typecontentOptions[6].options = response.result.states.map((r) => {
        return {
          value: r.stateId,
          roleId: r.stateId,
          label: r.name,
        };
      });
      settypecontentListOptions(typecontentOptions);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (!loaded) {
      loadDropdownlistOption();
      setLoaded(true);
    }
  }, []);

  const loadDropndownOptions = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      showLoader(false);
      let roles =
        props.routeData.userRolesKey === "distributorInternalUserRoles"
          ? response &&
            response.result &&
            response.result.distributorInternalUserRoles.map((res) => {
              return res;
            })
          : response &&
            response.result &&
            response.result.enterpriseInternalUserRoles.map((res) => {
              return res;
            });
      setUserRoles(roles);
      const internalOptions = [...internalFilterOptions];
      internalOptions[0].options = roles.map((r) => {
        return {
          value: r.roleId,
          roleId: r.roleId,
          label: r.name,
        };
      });
      setInternalFilterOptions(internalOptions);

      const contentOptions = [...contentFilterOptions];
      // LOB
      contentOptions[3].options = response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          roleId: r.lineOfBusinessId,
          label: r.name,
        };
      });
      // Type of Form
      contentOptions[8].options = response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          roleId: r.typeOfFormId,
          label: r.name,
        };
      });
      // Countries
      contentOptions[2].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });

      // State
      contentOptions[5].options = response.result.states.map((r) => {
        return {
          value: r.stateId,
          roleId: r.stateId,
          label: r.name,
        };
      });
      setContentFilterOptions(contentOptions);
      setParentPermissions(response.result.parentPermissions);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    setSelectedTab(tabId || TABS.BASIC);
    getEditData(id);
    setContentQuery(initialContentQuery);
  }, [id, tabId]);

  useEffect(() => {
    if (!loaded) {
      loadDropndownOptions();
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (formData.parentUser.permissions?.length === 0) {
      setFormData({
        ...formData,
        parentUser: {
          ...formData.parentUser,
          permissions: parentPermissions,
        },
      });
    }
  }, [parentPermissions]);

  const handleHandleChange = (event, newValue) => {
    setQuery({
      ...query,
      filter: {
        ...query.filter,
        userStatus: [0, 1],
      },
    });
    setContentQuery((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        status: [],
        contentType: [],
        published: "null",
        countries: [],
        states: [],
        lineOfBusiness: [],
        typeOfForm: [],
        contentAssociationStatus: [],
        TypeOfContent: [],
      },
    }));
    contentContext.setContentMode(MODE.LIST);
    catalogContext.setCatalogMode(MODE.LIST);
    bundleContext.setBundleMode(MODE.LIST);
    if (formData.userId > 0) {
      history.push(
        `${props.routeData.editRoute.replace(
          ":id",
          formData.userId
        )}/${newValue}`
      );
    }
    distributorProfileContext.setSelectedTab(newValue);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyInternalFilterHandler = (filter) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          userStatus: filter.status,
          AccountStatus: filter.accountStatus,
          roles:
            filter.role &&
            filter.role.map((r) => {
              return { roleId: r };
            }),
        },
      };
    });
  };

  const applytypecontentFilterHandler = (filter) => {
    ClearSelectionData();
    setContentQuery((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          lineOfBusiness:
            filter.lineOfBusiness?.map((item) => {
              return { lineOfBusinessId: item };
            }) || [],
          typeOfForm:
            filter.typeOfForm?.map((item) => {
              return { typeOfFormId: item };
            }) || [],
          countries:
            filter.countries?.map((item) => {
              return { countryId: item };
            }) || [],
          states:
            filter.states?.map((item) => {
              return { stateId: item };
            }) || [],
          contentType:
            filter.contentType?.map((item) => {
              return item;
            }) || [],
          status:
            filter.status?.map((item) => {
              return item;
            }) || [],
          contentAssociationStatus:
            filter.contentAssociationStatus?.map((item) => {
              return item;
            }) || [],
          publishStatus:
            filter.publishStatus?.map((item) => {
              return item;
            }) || [],
          contentFillable: filter.contentFillable,
        },
      };
    });
  };

  const applyContentFilterHandler = (filter) => {
    ClearSelectionData();
    setContentQuery((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          lineOfBusiness:
            filter?.lineOfBusiness?.map((item) => {
              return { lineOfBusinessId: item };
            }) || [],
          typeOfForm:
            filter?.typeOfForm?.map((item) => {
              return { typeOfFormId: item };
            }) || [],
          countries:
            filter?.countries?.map((item) => {
              return { countryId: item };
            }) || [],
          states:
            filter?.states?.map((item) => {
              return { stateId: item };
            }) || [],
          contentType:
            filter?.contentType?.map((item) => {
              return item;
            }) || [],
          status:
            filter?.status?.map((item) => {
              return item;
            }) || [],
          publishStatus:
            filter?.publishStatus?.map((item) => {
              return item;
            }) || [],
          TypeOfContent:
            filter?.TypeOfContent?.map((item) => {
              return item;
            }) || [],
          contentFillable: filter?.contentFillable,
        },
      };
    });
  };

  const onNextHandler = (isNext, user) => {
    if (isNext) {
      history.push(
        `${props.routeData.editRoute.replace(":id", user.userId)}/${
          TABS.INTERNAL_USERS
        }`
      );
    } else {
      getEditData(user.userId);
    }
  };

  const bulkUploadHandler = () => {
    setRefreshData(true);
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds(
        selectedTab === TABS.INTERNAL_USERS ? "userId" : "contentId"
      );
      let queryState =
        selectedTab === TABS.INTERNAL_USERS
          ? { ...query }
          : { ...contentQuery };
      queryState.SelectedIds = ids;
      let selectedCols = localStorage.getItem("selectedColumns")
        ? JSON.parse(localStorage.getItem("selectedColumns"))
        : null;
      let tabSelectionData = tabSelections(
        selectedTab,
        selectedCols,
        queryState
      );
      queryState.filter.selectedColumns =
        tabSelectionData &&
        tabSelectionData
          .filter((item) => item.isSelected && item.exportLabel !== "")
          .map((r) => r.exportLabel);
      showLoader(true);
      let dataObj = {
        selectedTab: selectedTab,
        queryState: queryState,
        props: props,
        userService: userService,
        id: id,
        contentId: contentId,
        selectedSubTab: selectedSubTab,
        catalogId: catalogId,
        bundleId: bundleId,
      };
      let exportedContent = await updateBySelectTab(dataObj);

      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
      setRefreshCallBack(true);
    } catch (e) {
      showLoader(false);
      console.log("err", e);
      toast.error("Internal server error");
    }
  };

  const onCancelTableColumnSelection = (value) => {
    setTableColumnModalVisibility(value);
  };

  const onCancelTableColumnSelectionInternal = (value) => {
    setTableColumnModalVisibilityInternal(value);
  };

  const searchCallback = (searchTerm) => {
    setQuery({ ...query, searchText: searchTerm });
  };

  const onViewHandler = (content, docType = DocumentType.CONTENT) => {
    if (docType === DocumentType.CATALOG) {
      distributorProfileContext.setSelectedTab(TABS.CATALOG);
      catalogContext.setSelectedTab(TABS.CONTENT_LIST);
      catalogContext.setCatalogMode(MODE.VIEW);
      setCatalogMode(MODE.VIEW);
      catalogContext.setCatalogId(content.contentId);
      setCatalogId(content.contentId);
      catalogContext.setUserId(content.userId);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("catalog")
        )}catalog/${TABS.CONTENT_LIST}`
      );
    } else if (docType === DocumentType.BUNDLE) {
      distributorProfileContext.setSelectedTab(TABS.BUNDLE);
      bundleContext.setSelectedTab(TABS.CONTENT_LIST);
      bundleContext.setBundleMode(MODE.VIEW);
      setBundleMode(MODE.VIEW);
      bundleContext.setBundleId(content.contentId);
      setBundleId(content.contentId);
      bundleContext.setUserId(content.userId);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("bundle")
        )}bundle/${TABS.CONTENT_LIST}`
      );
    } else {
      distributorProfileContext.setSelectedTab(TABS.CONTENT);
      setSelectedSubTab(TABS.CONTENT_PREVIEW);
      contentContext.setContentMode(MODE.VIEW);
      setContentMode(MODE.VIEW);
      contentContext.setContentId(content.contentId);
      setContentId(content.contentId);

      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("content")
        )}content/${TABS.CONTENT_PREVIEW}`
      );
    }
  };

  const onEditHandler = (content, docType = DocumentType.CONTENT) => {
    if (docType === DocumentType.CATALOG) {
      distributorProfileContext.setSelectedTab(TABS.CATALOG);
      catalogContext.setSelectedTab(TABS.CATALOG_INFORMATION);
      catalogContext.setCatalogMode(MODE.EDIT);
      setCatalogMode(MODE.EDIT);
      catalogContext.setCatalogId(content.contentId);
      setCatalogId(content.contentId);
      catalogContext.setUserId(content.userId);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("catalog")
        )}catalog/${TABS.CATALOG_INFORMATION}`
      );
    } else if (docType === DocumentType.BUNDLE) {
      distributorProfileContext.setSelectedTab(TABS.BUNDLE);
      bundleContext.setSelectedTab(TABS.BUNDLE_INFORMATION);
      bundleContext.setBundleMode(MODE.EDIT);
      setBundleMode(MODE.EDIT);
      bundleContext.setBundleId(content.contentId);
      setBundleId(content.contentId);
      bundleContext.setUserId(content.userId);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("bundle")
        )}bundle/${TABS.BUNDLE_INFORMATION}`
      );
    } else {
      distributorProfileContext.setSelectedTab(TABS.CONTENT);
      setSelectedSubTab(TABS.CONTENT_INFORMATION);
      contentContext.setContentMode(MODE.EDIT);
      setContentMode(MODE.EDIT);
      contentContext.setContentId(content.contentId);
      setContentId(content.contentId);

      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("content")
        )}content/${TABS.CONTENT_INFORMATION}`
      );
    }
  };
  /*istanbul ignore next */
  const onViewHandlerList = (content, docType = DocumentType.CONTENT) => {
    let location = window.location.pathname.includes("enterprisegroup")
      ? "enterprisegroup"
      : "distributor";
    if (docType === DocumentType.CATALOG) {
      distributorProfileContext.setSelectedTab(TABS.CATALOG);
      catalogContext.setSelectedTab(TABS.CATALOG_INFORMATION);
      catalogContext.setCatalogMode(MODE.VIEW);
      setCatalogMode(MODE.VIEW);
      catalogContext.setCatalogId(content.contentId);
      setCatalogId(content.contentId);
      history.push(
        `/${location}/${content.userId}/catalog/${TABS.CATALOG_INFORMATION}`
      );
    } else if (docType === DocumentType.BUNDLE) {
      distributorProfileContext.setSelectedTab(TABS.BUNDLE);
      bundleContext.setSelectedTab(TABS.BUNDLE_INFORMATION);
      bundleContext.setBundleMode(MODE.VIEW);
      setBundleMode(MODE.VIEW);
      bundleContext.setBundleId(content.contentId);
      setBundleId(content.contentId);
      history.push(
        `/${location}/${content.userId}/bundle/${TABS.BUNDLE_INFORMATION}`
      );
    } else {
      distributorProfileContext.setSelectedTab(TABS.CONTENT);
      contentContext.setSubSelectedTab(TABS.CONTENT_INFORMATION);
      contentContext.setContentMode(MODE.VIEW);
      setContentMode(MODE.VIEW);
      contentContext.setContentId(content.contentId);
      setContentId(content.contentId);
      history.push(
        `/${location}/${content.userId}/content/${TABS.CONTENT_INFORMATION}`
      );
    }
  };
  /*istanbul ignore next */
  const onEditHandlerList = (content, docType = DocumentType.CONTENT) => {
    let location = window.location.pathname.includes("enterprisegroup")
      ? "enterprisegroup"
      : "distributor";
    if (docType === DocumentType.CATALOG) {
      distributorProfileContext.setSelectedTab(TABS.CATALOG);
      catalogContext.setSelectedTab(TABS.CATALOG_INFORMATION);
      catalogContext.setCatalogMode(MODE.EDIT);
      setCatalogMode(MODE.EDIT);
      catalogContext.setCatalogId(content.contentId);
      setCatalogId(content.contentId);
      history.push(
        `/${location}/${content.userId}/catalog/${TABS.CATALOG_INFORMATION}`
      );
    } else if (docType === DocumentType.BUNDLE) {
      distributorProfileContext.setSelectedTab(TABS.BUNDLE);
      bundleContext.setSelectedTab(TABS.BUNDLE_INFORMATION);
      bundleContext.setBundleMode(MODE.EDIT);
      setBundleMode(MODE.EDIT);
      bundleContext.setBundleId(content.contentId);
      setBundleId(content.contentId);
      history.push(
        `/${location}/${content.userId}/bundle/${TABS.BUNDLE_INFORMATION}`
      );
    } else {
      distributorProfileContext.setSelectedTab(TABS.CONTENT);
      contentContext.setSubSelectedTab(TABS.CONTENT_INFORMATION);
      contentContext.setContentMode(MODE.EDIT);
      setContentMode(MODE.EDIT);
      contentContext.setContentId(content.contentId);
      setContentId(content.contentId);
      history.push(
        `/${location}/${content.userId}/content/${TABS.CONTENT_INFORMATION}`
      );
    }
  };

  const onQueryChange = (updatedQuery) => {
    setContentQuery(updatedQuery);
  };

  const onCopyHandler = (from) => {
    if (from === "catalog") {
      distributorProfileContext.setSelectedTab(TABS.CATALOG);
      catalogContext.setSelectedTab(TABS.CATALOG_INFORMATION);
      catalogContext.setCatalogMode(MODE.ADD);
      catalogContext.setCatalogId(0);
      catalogContext.copyCatalog({ contentId: catalogId });
      setCatalogMode(MODE.ADD);
      setCatalogId(0);
    } else if (from === "bundle") {
      distributorProfileContext.setSelectedTab(TABS.BUNDLE);
      bundleContext.setSelectedTab(TABS.BUNDLE_INFORMATION);
      bundleContext.setBundleMode(MODE.ADD);
      bundleContext.setBundleId(0);
      bundleContext.copyBundle({ contentId: bundleId });
      setBundleMode(MODE.ADD);
      setBundleId(0);
    } else {
      distributorProfileContext.setSelectedTab(TABS.CONTENT);
      contentContext.setSubSelectedTab(TABS.CONTENT_INFORMATION);
      contentContext.setContentMode(MODE.ADD);
      contentContext.setContentId(0);
      contentContext.copyContent({ contentId: contentId });
      setContentMode(MODE.ADD);
      setContentId(0);
    }
  };

  const onSaveHandler = (from, content) => {
    if (from === "catalog") {
      setCatalogMode(MODE.LIST);
      catalogContext.setCatalogMode(MODE.LIST);
      history.push(`/distributor/${content.userId}/catalog`);
    } else if (from === "bundle") {
      setBundleMode(MODE.LIST);
      bundleContext.setBundleMode(MODE.LIST);
      history.push(`/distributor/${content.userId}/bundle`);
    } else {
      setContentMode(MODE.LIST);
      contentContext.setContentMode(MODE.LIST);
      history.push(`/distributor/${content.userId}/content`);
    }
  };

  const onCancelHandler = (from = "") => {
    if (from === "catalog") {
      setCatalogMode(MODE.LIST);
      catalogContext.setCatalogMode(MODE.LIST);
      catalogContext.resetCopyCatalog();
    } else if (from === "bundle") {
      setBundleMode(MODE.LIST);
      bundleContext.setBundleMode(MODE.LIST);
      bundleContext.resetCopyBundle();
    } else {
      setContentMode(MODE.LIST);
      contentContext.setContentMode(MODE.LIST);
      contentContext.resetCopyContent();
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("content")
        )}${TABS.CONTENT}`
      );
    }
  };

  const onContentSaveNextHandler = (action, value, from = "") => {
    if (from === "catalog") {
      distributorProfileContext.setSelectedTab(TABS.CATALOG);
      if (action === "Update") {
        catalogContext.setSelectedTab(TABS.ENROLLED_USERS);
      } else {
        catalogContext.setSelectedTab(TABS.CONTENT_LIST);
      }
      history.push(`/distributor/${id}/catalog/${TABS.CONTENT_LIST}`);
      if (action === "Save") {
        catalogContext.setCatalogMode(MODE.EDIT);
        setCatalogMode(MODE.VIEW);
      }
      catalogContext.setCatalogId(value);
      setCatalogId(value);
    } else if (from === "bundle") {
      distributorProfileContext.setSelectedTab(TABS.BUNDLE);
      if (action === "Update") {
        bundleContext.setSelectedTab(TABS.ENROLLED_USERS);
      } else {
        bundleContext.setSelectedTab(TABS.CONTENT_LIST);
      }
      history.push(`/distributor/${id}/bundle/${TABS.CONTENT_LIST}`);
      if (action === "Save") {
        bundleContext.setBundleMode(MODE.EDIT);
        setBundleMode(MODE.VIEW);
      }
      bundleContext.setBundleId(value);
      setBundleId(value);
    } else {
      distributorProfileContext.setSelectedTab(TABS.CONTENT);
      contentContext.setSelectedTab(TABS.CONTENT_PREVIEW);
      if (action === "Save") {
        contentContext.setContentMode(MODE.VIEW);
        setContentMode(MODE.VIEW);
      }
      history.push(`/distributor/${id}/content/${TABS.CONTENT_PREVIEW}`);
      contentContext.setContentId(value);
      setContentId(value);
    }
  };

  const whenUserTypeNot4 =
    props?.routeData?.userTypeId === 3 ? (
      <CreateDistributorModal
        routeData={props.routeData}
        formData={formData}
        onNext={onNextHandler}
        isEditMode={page.isEditMode}
        permissions={permissions}
        PermissionEnum={PermissionEnum}
        getEditData={getEditData}
        basicDetailPermission={childModulePermissions(
          getModuleId(props?.routeData?.userTypeId),
          SystemModulesEnum.DISTRIBUTOR_PROFILE_BASIC_DETAILS
        )}
        associationDetailsPermission={childModulePermissions(
          getModuleId(props?.routeData?.userTypeId),
          SystemModulesEnum.DISTRIBUTOR_PROFILE_ASSOCIATION_DETAILS
        )}
        subscriptionDetailsPermission={childModulePermissions(
          getModuleId(props?.routeData?.userTypeId),
          SystemModulesEnum.DISTRIBUTOR_PROFILE_SUBSCRIPTION_DETAILS
        )}
      />
    ) : (
      // for subscriber module
      <CreateDistributorModal
        routeData={props.routeData}
        formData={formData}
        onNext={onNextHandler}
        isEditMode={page.isEditMode}
        permissions={permissions}
        PermissionEnum={PermissionEnum}
        getEditData={getEditData}
        basicDetailPermission={PermissionService(props?.routeData?.moduleId)}
        associationDetailsPermission={PermissionService(
          props?.routeData?.moduleId
        )}
      />
    );
  return (
    <Grid spacing={0} container className="distributor-container">
      <Grid
        spacing={0}
        container
        className="distributor-profile-form custom-width"
      >
        <Grid item xs={12}>
          <BasicInfo
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            id={id}
            options={internalFilterOptions}
            internalFilterOptions={internalFilterOptions}
            contentContext={contentContext}
            useBundleContext={useBundleContext}
            setContentId={setContentId}
            setBundleId={setBundleId}
            setCatalogId={setCatalogId}
            useContentContext={useContentContext}
            useCatalogContext={useCatalogContext}
            catalogContext={catalogContext}
            bundleContext={bundleContext}
            contentMode={contentMode}
            catalogMode={catalogMode}
            bundleMode={bundleMode}
            applytypecontentFilterHandler={applytypecontentFilterHandler}
            typecontentListOptions={typecontentListOptions}
            settypecontentListOptions={settypecontentListOptions}
            setBundleMode={setBundleMode}
            setCatalogMode={setCatalogMode}
            setContentMode={setContentMode}
            contentFilterOptions={contentFilterOptions}
            catalogFilterOptions={catalogFilterOptions}
            bundleFilterOptions={bundleFilterOptions}
            setContentFilterOptions={setContentFilterOptions}
            setSelectedSubTab={setSelectedSubTab}
            setCatalogFilterOptions={setCatalogFilterOptions}
            setBundleFilterOptions={setBundleFilterOptions}
            createUserView={createUserView}
            setCreateUserView={setCreateUserView}
            tableColumnModalVisibility={tableColumnModalVisibility}
            tableColumnModalVisibilityInternal={
              tableColumnModalVisibilityInternal
            }
            loadDropndownOption={loadDropndownOption}
            setTableColumnModalVisibilityInternal={
              setTableColumnModalVisibilityInternal
            }
            setTableColumnModalVisibility={setTableColumnModalVisibility}
            currentUser={currentUser}
            handleClick={handleClick}
            PermissionEnum={PermissionEnum}
            open={open}
            selectedTab={selectedTab}
            tabPermissions={tabPermissions}
            allUsersExportPermission={allUsersExportPermission}
            selectedSubTab={selectedSubTab}
            setSelectedTab={setSelectedTab}
            location={location}
            bulkUploadTemplate={bulkUploadTemplate}
            onCopyHandler={onCopyHandler}
            handleExport={handleExport}
            bulkUploadHandler={bulkUploadHandler}
            applyContentFilterHandler={applyContentFilterHandler}
            handleHandleChange={handleHandleChange}
            applyInternalFilterHandler={applyInternalFilterHandler}
            handleClose={handleClose}
            formData={formData}
            TABS={TABS}
          />
        </Grid>
        <Grid
          spacing={3}
          item
          container
          marginLeft={1}
          marginRight={1}
          marginTop={0}
          className="custom-width"
        >
          <Grid
            item
            md={9}
            lg={11}
            sm={12}
            xs={12}
            className="distributor-profile-form custom-marginleft custom-width-box position-fix-panel"
          >
            <Box
              p={1}
              mr={4}
              className="right-section"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleHandleChange}
                aria-label="Distributor Tabs"
              >
                {props.routeData.profileTabs.map((item) => {
                  return (
                    enabledTabs(
                      getModuleId(props?.routeData?.userTypeId)
                    )?.includes(item.module_name) && (
                      <Tab
                        className="my-tabs"
                        label={item.name}
                        value={item.label}
                        {...a11yProps(item.label, item.label)}
                        disabled={item.disabled}
                      />
                    )
                  );
                })}
              </Tabs>

              <TabPanel
                value={selectedTab}
                index={TABS.BASIC}
                className="distributor-tab-panel"
              >
                {props?.routeData?.userTypeId === 4 ? (
                  <CreateEnterpriseModal
                    routeData={props.routeData}
                    formData={formData}
                    onNext={onNextHandler}
                    isEditMode={page.isEditMode}
                    permissions={permissions}
                    PermissionEnum={PermissionEnum}
                    getEditData={getEditData}
                    basicDetailPermission={childModulePermissions(
                      getModuleId(props?.routeData?.userTypeId),
                      SystemModulesEnum.ENTERPRISE_PROFILE_BASIC_DETAILS
                    )}
                    associationDetailsPermission={childModulePermissions(
                      getModuleId(props?.routeData?.userTypeId),
                      SystemModulesEnum.ENTERPRISE_PROFILE_ASSOCIATION_DETAILS
                    )}
                  />
                ) : (
                  whenUserTypeNot4
                )}
              </TabPanel>

              {childModulePermissions(
                getModuleId(props?.routeData?.userTypeId),
                props?.routeData?.userTypeId === SystemUsersEnum.DISTRIBUTOR ||
                  props?.routeData?.userTypeId ===
                    SystemUsersEnum.DISTRIBUTOR_INTERNAL
                  ? SystemModulesEnum.DISTRIBUTOR_INTERNAL_USER
                  : SystemModulesEnum.ENTERPRISE_INTERNAL_USER
              )?.includes(PermissionEnum.LIST) && (
                <TabPanel value={selectedTab} index={TABS.INTERNAL_USERS}>
                  {createUserView && id && (
                    <AddEditInternalUserModal
                      routeData={props.routeData}
                      onSuccess={setRefreshCallBack}
                      data={addInternalUserFormData}
                      distributorData={formData}
                      userRolesDropdown={userRoles}
                      handleCloseModalCallback={handleCloseModalCallback}
                      permissions={tabPermissions}
                    />
                  )}
                  <InternalUsers
                    searchCallback={searchCallback}
                    routeData={props.routeData}
                    id={id}
                    query={query}
                    distributorData={formData}
                    userRolesDropdown={userRoles}
                    isRefresh={refreshData}
                    onSuccess={setRefreshCallBack}
                    showColumnPopup={tableColumnModalVisibilityInternal}
                    onCancelTableColumnSelection={
                      onCancelTableColumnSelectionInternal
                    }
                    permissions={tabPermissions}
                  />
                </TabPanel>
              )}

              {childModulePermissions(
                getModuleId(props?.routeData?.userTypeId),
                props?.routeData?.userTypeId === SystemUsersEnum.DISTRIBUTOR ||
                  props?.routeData?.userTypeId ===
                    SystemUsersEnum.DISTRIBUTOR_INTERNAL
                  ? SystemModulesEnum.DISTRIBUTOR_CONTENT
                  : SystemModulesEnum.ENTERPRISE_CONTENT
              )?.includes(PermissionEnum.LIST) && (
                <TabPanel
                  value={selectedTab}
                  index={TABS.CONTENT}
                  className="distributor-tab-panel"
                >
                  <Content
                    id={id}
                    distributorId={formData?.systemGeneratedId}
                    signUpApproval={formData?.signUpApproval}
                    contentId={contentId}
                    mode={contentMode}
                    query={contentQuery}
                    onView={onViewHandler}
                    setSelectedSubTab={setSelectedSubTab}
                    onEdit={onEditHandler}
                    onQueryChange={onQueryChange}
                    onSave={onSaveHandler}
                    onCancel={onCancelHandler}
                    onNext={onContentSaveNextHandler}
                    showColumnPopup={tableColumnModalVisibility}
                    onCancelTableColumnSelection={onCancelTableColumnSelection}
                    isMassUploadPermission={childModulePermissions(
                      getModuleId(props?.routeData?.userTypeId),
                      SystemModulesEnum.DISTRIBUTOR_CONTENT
                    )?.includes(PermissionEnum.CREATE)}
                    permissions={tabPermissions}
                  />
                </TabPanel>
              )}

              {childModulePermissions(
                getModuleId(props?.routeData?.userTypeId),
                props?.routeData?.userTypeId === SystemUsersEnum.DISTRIBUTOR ||
                  props?.routeData?.userTypeId ===
                    SystemUsersEnum.DISTRIBUTOR_INTERNAL
                  ? SystemModulesEnum.DISTRIBUTOR_CATALOG
                  : SystemModulesEnum.ENTERPRISE_CATALOG
              )?.includes(PermissionEnum.LIST) && (
                <TabPanel
                  value={selectedTab}
                  index={TABS.CATALOG}
                  className="distributor-tab-panel"
                >
                  <Catalog
                    id={id}
                    distributorId={formData?.systemGeneratedId}
                    signUpApproval={formData?.signUpApproval}
                    catalogId={catalogId}
                    mode={catalogMode}
                    query={contentQuery}
                    onView={onViewHandler}
                    setSelectedSubTab={setSelectedSubTab}
                    onSave={onSaveHandler}
                    onCancel={onCancelHandler}
                    onNext={onContentSaveNextHandler}
                    onEdit={onEditHandler}
                    onQueryChange={onQueryChange}
                    showColumnPopup={tableColumnModalVisibility}
                    onCancelTableColumnSelection={onCancelTableColumnSelection}
                    applyContentFilterHandler={applyContentFilterHandler}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    permissions={tabPermissions}
                    setContentId={setContentId}
                    setContentMode={setContentMode}
                  />
                </TabPanel>
              )}

              {childModulePermissions(
                getModuleId(props?.routeData?.userTypeId),
                props?.routeData?.userTypeId === SystemUsersEnum.DISTRIBUTOR ||
                  props?.routeData?.userTypeId ===
                    SystemUsersEnum.DISTRIBUTOR_INTERNAL
                  ? SystemModulesEnum.DISTRIBUTOR_BUNDLE
                  : SystemModulesEnum.ENTERPRISE_BUNDLE
              )?.includes(PermissionEnum.LIST) && (
                <TabPanel
                  value={selectedTab}
                  index={TABS.BUNDLE}
                  className="distributor-tab-panel"
                >
                  <Bundle
                    id={id}
                    distributorId={formData?.systemGeneratedId}
                    signUpApproval={formData?.signUpApproval}
                    catalogId={bundleId}
                    mode={bundleMode}
                    query={contentQuery}
                    onView={onViewHandler}
                    onViewHandlerList={onViewHandlerList}
                    onEditHandlerList={onEditHandlerList}
                    setSelectedSubTab={setSelectedSubTab}
                    onSave={onSaveHandler}
                    onCancel={onCancelHandler}
                    onNext={onContentSaveNextHandler}
                    onEdit={onEditHandler}
                    onQueryChange={onQueryChange}
                    showColumnPopup={tableColumnModalVisibility}
                    onCancelTableColumnSelection={onCancelTableColumnSelection}
                    permissions={tabPermissions}
                    setContentId={setContentId}
                    setContentMode={setContentMode}
                    setBundleId={setBundleId}
                    setBundleMode={setBundleMode}
                    setCatalogId={setCatalogId}
                    setCatalogMode={setCatalogMode}
                  />
                </TabPanel>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DistributorProfile;
function switchByTab(selectedTab, userPathRole) {
  let selectedSubModule;
  switch (selectedTab) {
    case TABS.INTERNAL_USERS:
      selectedSubModule = tabinternalUsers(userPathRole, selectedSubModule);
      break;
    case TABS.CONTENT:
      selectedSubModule = tabContent(userPathRole, selectedSubModule);
      break;
    case TABS.CATALOG:
      selectedSubModule = tabCatalog(userPathRole, selectedSubModule);
      break;
    case TABS.BUNDLE:
      selectedSubModule = tabBundle(userPathRole, selectedSubModule);
      break;
    case TABS.BASIC:
    default:
      if (userPathRole) {
        selectedSubModule = SystemModulesEnum.DISTRIBUTOR_PROFILE_BASIC_DETAILS;
      } else {
        selectedSubModule = SystemModulesEnum.ENTERPRISE_PROFILE_BASIC_DETAILS;
      }
      break;
  }
  return selectedSubModule;
}

function tabBundle(userPathRole, selectedSubModule) {
  if (userPathRole) {
    selectedSubModule = SystemModulesEnum.DISTRIBUTOR_BUNDLE;
  } else {
    selectedSubModule = SystemModulesEnum.ENTERPRISE_BUNDLE;
  }
  return selectedSubModule;
}

function tabCatalog(userPathRole, selectedSubModule) {
  if (userPathRole) {
    selectedSubModule = SystemModulesEnum.DISTRIBUTOR_CATALOG;
  } else {
    selectedSubModule = SystemModulesEnum.ENTERPRISE_CATALOG;
  }
  return selectedSubModule;
}

function tabContent(userPathRole, selectedSubModule) {
  if (userPathRole) {
    selectedSubModule = SystemModulesEnum.DISTRIBUTOR_CONTENT;
  } else {
    selectedSubModule = SystemModulesEnum.ENTERPRISE_CONTENT;
  }
  return selectedSubModule;
}

function tabinternalUsers(userPathRole, selectedSubModule) {
  if (userPathRole) {
    selectedSubModule = SystemModulesEnum.DISTRIBUTOR_INTERNAL_USER;
  } else {
    selectedSubModule = SystemModulesEnum.ENTERPRISE_INTERNAL_USER;
  }
  return selectedSubModule;
}

function getPathRole(roleId, props) {
  return (roleId === SystemUsersEnum.DISTRIBUTOR ||
    roleId === SystemUsersEnum.DISTRIBUTOR_INTERNAL ||
    roleId === SystemUsersEnum.SUPERADMIN ||
    roleId === SystemUsersEnum.ADMIN) &&
    props.routeData?.title === "Distributors"
    ? true
    : false;
}

async function updateBySelectTab(dataObj) {
  let exportedContent;
  if (dataObj.selectedTab === TABS.INTERNAL_USERS) {
    dataObj.queryState.filter.selectedColumns = GetSelectedColumns(
      dataObj.props?.routeData?.internalUserTypeId
    );
    exportedContent = await dataObj.userService.exportDataInternalUsers(
      `?${dataObj.props.routeData.queryVar}=${
        dataObj.id
      }&query=${JSON.stringify(dataObj.queryState)}`
    );
  } else if (dataObj.selectedTab === TABS.CONTENT) {
    exportedContent = await onTabContent(
      dataObj.id,
      dataObj.contentId,
      dataObj.selectedSubTab,
      dataObj.queryState,
      exportedContent,
      dataObj.userService
    );
  } else if (dataObj.selectedTab === TABS.CATALOG) {
    exportedContent = await onTabCatalog(
      dataObj.id,
      dataObj.catalogId,
      dataObj.selectedSubTab,
      dataObj.queryState,
      exportedContent,
      dataObj.userService
    );
  } else if (dataObj.selectedTab === TABS.BUNDLE) {
    exportedContent = await onTabBundle(
      dataObj.id,
      dataObj.bundleId,
      dataObj.selectedSubTab,
      dataObj.queryState,
      exportedContent,
      dataObj.userService
    );
  }
  return exportedContent;
}

function tabSelections(selectedTab, selectedCols, queryState) {
  const whenNotContent =
    selectedTab === TABS.CATALOG ? selectedCols?.catalog : whenNotCatalog();
  return selectedTab === TABS.CONTENT ? selectedCols?.contents : whenNotContent;

  function whenNotCatalog() {
    return selectedTab === TABS.BUNDLE
      ? selectedCols?.bundle
      : queryState.filter.selectedColumns;
  }
}

async function onTabCatalog(
  id,
  catalogId,
  selectedSubTab,
  queryState,
  exportedContent,
  userService
) {
  let firstParam = 0;
  let secondParam = 0;
  if (window.location.pathname.includes("distributor")) {
    firstParam = Number(id);
    secondParam = catalogId;
  } else if (window.location.pathname.includes("enterprisegroup")) {
    firstParam = -1;
    secondParam = Number(id);
  }
  let selectedCols = localStorage.getItem("selectedColumns")
    ? JSON.parse(localStorage.getItem("selectedColumns"))
    : null;
  if (selectedSubTab === "ContentList") {
    queryState.filter.selectedColumns = getSelectedCols(
      selectedCols,
      "listOfContentCatalogDataColumns"
    );
    exportedContent = await userService.exportAssociatedDataCatalog(
      `${firstParam}/${secondParam}/${encodeURIComponent(
        JSON.stringify(queryState)
      )}`
    );
  } else {
    queryState.filter.selectedColumns = getSelectedCols(
      selectedCols,
      "catalog"
    );
    exportedContent = await userService.exportDataCatalog(
      `${firstParam}/${secondParam}/${encodeURIComponent(
        JSON.stringify(queryState)
      )}`
    );
  }
  return exportedContent;
}

async function onTabBundle(
  id,
  bundleId,
  selectedSubTab,
  queryState,
  exportedContent,
  userService
) {
  let firstParam = 0;
  let secondParam = 0;
  if (window.location.pathname.includes("distributor")) {
    firstParam = Number(id);
    secondParam = bundleId;
  } else if (window.location.pathname.includes("enterprisegroup")) {
    firstParam = -1;
    secondParam = Number(id);
  }
  let selectedCols = localStorage.getItem("selectedColumns")
    ? JSON.parse(localStorage.getItem("selectedColumns"))
    : null;
  if (selectedSubTab === "ContentList") {
    queryState.filter.selectedColumns = getSelectedCols(
      selectedCols,
      "listcustombundledatacolumns"
    );
    exportedContent = await userService.exportAssociatedDataBundle(
      `${firstParam}/${secondParam}/${encodeURIComponent(
        JSON.stringify(queryState)
      )}`
    );
  } else {
    queryState.filter.selectedColumns = getSelectedCols(selectedCols, "bundle");
    exportedContent = await userService.exportDataBundle(
      `${firstParam}/${secondParam}/${encodeURIComponent(
        JSON.stringify(queryState)
      )}`
    );
  }
  return exportedContent;
}

async function onTabContent(
  id,
  contentId,
  selectedSubTab,
  queryState,
  exportedContent,
  userService
) {
  let firstParam = 0;
  let secondParam = 0;
  if (window.location.pathname.includes("distributor")) {
    firstParam = Number(id);
    secondParam = contentId;
  } else if (window.location.pathname.includes("enterprisegroup")) {
    firstParam = -1;
    secondParam = Number(id);
  }
  let selectedCols = localStorage.getItem("selectedColumns")
    ? JSON.parse(localStorage.getItem("selectedColumns"))
    : null;
  if (selectedSubTab === "ContentList") {
    queryState.filter.selectedColumns = getSelectedCols(
      selectedCols,
      "listOfContentCatalogDataColumns"
    );
    exportedContent = await userService.exportDataContent(
      `${firstParam}/${secondParam}/${encodeURIComponent(
        JSON.stringify(queryState)
      )}`
    );
  } else {
    queryState.filter.selectedColumns = getSelectedCols(
      selectedCols,
      "contents"
    );
    exportedContent = await userService.exportDataContent(
      `${firstParam}/${secondParam}/${encodeURIComponent(
        JSON.stringify(queryState)
      )}`
    );
  }
  return exportedContent;
}
