import { COLUMN_TYPES } from "../../constants/common";

export const DefaultCatalogColumns = [
  {
    exportLabel: "Catalog Name",
    fieldName: "name",
    label: "Catalog Name",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    sort: true,
    width: 150,
  },
  {
    exportLabel: "LOB",
    fieldName: "lineOfBusinessList",
    label: "LOB",
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 140,
  },
  {
    fieldName: "countryName",
    label: "Country",
    exportLabel: "Country",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },

  {
    fieldName: "states",
    label: "State/Province",
    exportLabel: "State/Province",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 150,
  },
  {
    fieldName: "latestVersion",
    label: "Latest Version",
    exportLabel: "Latest Version",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "keywords",
    label: "Keywords",
    exportLabel: "Keywords",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },
  {
    fieldName: "marketingDescription",
    label: "Marketing Description",
    exportLabel: "Marketing Description",
    sort: false,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 100,
  },

  {
    fieldName: "createdOn",
    label: "Created Date",
    exportLabel: "Created Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "createdByName",
    label: "Created By",
    exportLabel: "Created By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedOn",
    label: "Last Updated Date",
    exportLabel: "Last Updated Date",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "updatedByName",
    label: "Last Updated By",
    exportLabel: "Last Updated By",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "numberOfEnrolledUser",
    label: "Number of Enrolled Users",
    exportLabel: "Number of Enrolled Users",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 230,
  },

  {
    fieldName: "isPublic",
    label: "Available to Public",
    exportLabel: "Available to Public",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Yes",
    NoLabelText: "No",
    showInSelection: true,
    width: 200,
  },
  {
    fieldName: "contentTypeText",
    label: "Catalog Type(Free/Paid)",
    exportLabel: "Catalog Type(Free/Paid)",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    width: 220,
  },
  {
    fieldName: "publishStatus",
    label: "Published",
    exportLabel: "Published",
    sort: true,
    isSelected: false,
    type: COLUMN_TYPES.BOOLEAN,
    YesLabelText: "Published",
    NoLabelText: "Draft",
    showInSelection: true,
    width: 120,
  },
  {
    exportLabel: "Status",
    fieldName: "status",
    label: "Status",
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    showInSelection: true,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    sort: true,
    isFixed: true,
    width: 200,
    rightPadding: 190,
  },
  {
    fieldName: "action",
    label: "",
    exportLabel: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    showInSelection: true,
    isFixed: true,
    rightPadding: 0,
    width: 190,
    options: [
      {
        label: "Add to Favourite",
        testId: "favourite",
        status: "isFavorite",
        iconPath: "/images/favorite_enable.png",
        iconPathInactive: "/images/unfavorite_enable.png",
        disabled: false,
        testIdText: "favorite",
        activeTitle: "Add to Favorite",
        deactiveTitle: "Remove Favorite",
      },
      {
        label: "Delete",
        testId: "delete",
        status: "publishStatus",
        iconPath: "/images/delete_disable.png",
        iconPathInactive: "/images/delete_enable.png",
        disabled: false,
        testIdText: "DeleteIcon",
        activeTitle: "Delete Content",
        deactiveTitle: "Published content cannot be deleted",
      },
      {
        label: "Copy",
        testId: "copy",
        // status: "publishStatus",
        iconPath: "/images/copy-icon.png",
        iconPathInactive: "/images/copy-icon.png",
        disabled: false,
        testIdText: "CopyIcon",
        activeTitle: "Copy Content",
        deactiveTitle: "Copy Content",
      },
    ],
  },
  // {
  //   fieldName: "dynamicAction",
  //   label: "",
  //   exportLabel: "",
  //   sort: false,
  //   isSelected: true,
  //   type: COLUMN_TYPES.DYNAMIC_ACTION,
  //   showInSelection: true,
  //   options: [
  //     {
  //       label: "Favourite",
  //       testId: "favourite",
  //       status: "isFavorite",
  //       iconPath: "/images/favorite_enable.png",
  //       iconPathInactive: "/images/unfavorite_enable.png",
  //       disabled: false,
  //       testIdText: "favorite",
  //       activeTitle: "Favorite",
  //       deactiveTitle: "Favorite",
  //     },
  //     {
  //       label: "Delete",
  //       testId: "delete",
  //       status: "publishStatus",
  //       iconPath: "/images/delete_disable.png",
  //       iconPathInactive: "/images/delete_enable.png",
  //       disabled: false,
  //       testIdText: "DeleteIcon",
  //       activeTitle: "Delete Content",
  //       deactiveTitle: "Published content cannot be deleted",
  //     },
  //   ],
  //   width: 20,
  // },
];
