import React, { useEffect, useState } from "react";
import { Prompt, Route, useLocation } from "react-router-dom";
import "../../App.css";
import "react-toastify/dist/ReactToastify.css";

import Dashboard from "../../screens/Dashboard/Dashboard";
import Distributors from "../../screens/Distributors/Distributors";
import ApprovalRequests from "../../screens/ApprovalRequests/ApprovalRequests";
import ContentPage from "../../screens/ContentPage/ContentPage";
import Reports from "../../screens/Reports/Reports";
import DistributorProfile from "../../screens/Distributors/DistributorProfile";
import Navigation from "../../common/Sidebar/Navigation";
import Header from "../../common/Header/Header";
import { UserTypes } from "../../constants/user-types";
import { useAuth } from "../../hooks/useAuth";
import Login from "./Login";
import LandingPage from "../../screens/LandingPage/LandingPage";
import ManageRoles from "../../screens/ManageRoles/ManageRoles";
import ConfirmApproval from "../../screens/ConfirmApproval/ConfirmApproval";
import ConfirmSignup from "../../screens/ConfirmSignup/ConfirmSignup";
import CatalogPage from "../../screens/Catalog/CatalogPage";
import { usePageMode } from "../../hooks/usePageMode";
import { exitConfirmationMessage } from "../../screens/Distributors/constants";
import BundlePage from "../../screens/Bundle/BundlePage";
import { useCatalogContext } from "../../screens/Distributors/Catalog/Catalog.context";
import { useContentContext } from "../../screens/Distributors/Content/Content.context";
import { MODE, TABS } from "../../constants/common";
import { useBundleContext } from "../../screens/Distributors/Bundle/Bundle.context";
import ManageModules from "../../screens/ManageModules/ManageModules";
import ForgotPassword from "./ForgotPassword";
import ActivateUser from "./ActivateUser";
import SecureRoute from "./SecureRoute";
import ManageDocuments from "../../screens/ManageDocuments/ManageDocuments";
import SignUpPage from "./SignUpPage";
import Share from "./Share";
import { useHistory } from "react-router";

const PrivateRoute = () => {
  const auth = useAuth();
  const page = usePageMode();
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const user = auth.getCurrentUser();
  const isAuth = user !== null;
  const selectedRole = auth.getRole();
  const catalogContext = useCatalogContext();
  const contentContext = useContentContext();
  const bundleContext = useBundleContext();

  useEffect(() => {
    if (selectedRole) {
      // if route is "/share", header & left menu should be hidden
      setShow(!location.pathname.includes("/share"));
    } else {
      setShow(false);
    }
  }, [selectedRole]);

  useEffect(() => {
    // After confirm/Cancel reset dirty flag
    page.setDirty(false);
    catalogContext.setSelectedTab(TABS.CATALOG);
    catalogContext.setCatalogMode(MODE.LIST);
    contentContext.setSelectedTab(TABS.CONTENT);
    contentContext.setContentMode(MODE.LIST);
    bundleContext.setSelectedTab(TABS.BUNDLE);
    bundleContext.setBundleMode(MODE.LIST);
    let locationArr = location.pathname.split("/").filter((x) => x);
    page.setBreadcrumbRoute(locationArr);
  }, [location]);
  const authState = localStorage.getItem("loginData") ? true : false;
  const beforeLoginURL = ["/", "/reset-password", "/activate", "/signup"];
  if (beforeLoginURL.includes(location.pathname) && authState) {
    history.push("/login");
  }

  return (
    <div>
      <div className={`${show ? "main-container" : ""}`}>
        {show && <Navigation />}
        <div className={`${show ? "app-container" : ""}`}>
          {page.isDirty && (
            <Prompt when={page.isDirty} message={exitConfirmationMessage} />
          )}
          <div className="header">{show && <Header />}</div>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/share/:id" component={Share} />
          <Route exact path="/reset-password" component={ForgotPassword} />
          <Route exact path="/activate" component={ActivateUser} />
          <Route exact path="/signup" component={SignUpPage} />
          <SecureRoute
            exact
            path="/distributor/:id"
            component={DistributorProfile}
            routeData={UserTypes.distributor}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/distributor/:id/:tabId"
            component={DistributorProfile}
            routeData={UserTypes.distributor}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/distributor/:id/:tabId/:subTabId"
            component={DistributorProfile}
            routeData={UserTypes.distributor}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/distributor"
            component={DistributorProfile}
            routeData={UserTypes.distributor}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/distributors"
            component={Distributors}
            routeData={UserTypes.distributor}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/enterprisegroup/:id"
            component={DistributorProfile}
            routeData={UserTypes.enterpriseGroupUser}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/enterprisegroup/:id/:tabId"
            component={DistributorProfile}
            routeData={UserTypes.enterpriseGroupUser}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/enterprisegroup/:id/:tabId/:subTabId"
            component={DistributorProfile}
            routeData={UserTypes.enterpriseGroupUser}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/enterprisegroup"
            component={DistributorProfile}
            routeData={UserTypes.enterpriseGroupUser}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/enterprisegroups"
            component={Distributors}
            routeData={UserTypes.enterpriseGroupUser}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/subscriber"
            component={DistributorProfile}
            routeData={UserTypes.subscriber}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/subscriber/:id"
            component={DistributorProfile}
            routeData={UserTypes.subscriber}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/subscriber/:id/:tabId"
            component={DistributorProfile}
            routeData={UserTypes.subscriber}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/subscribers"
            component={Distributors}
            routeData={UserTypes.subscriber}
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={Reports}
            path="/Reports"
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={ManageRoles}
            path="/role-management"
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={ManageModules}
            path="/module-management"
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={CatalogPage}
            path="/catalog"
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={ContentPage}
            path="/content"
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={BundlePage}
            path="/bundle"
            auth={isAuth}
          />
          <SecureRoute
            exact
            component={ApprovalRequests}
            path="/approvals"
            auth={isAuth}
          />
          <SecureRoute path="/users" component={Dashboard} auth={isAuth} />
          <SecureRoute
            exact
            path="/dashboard"
            component={LandingPage}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/manage-documents"
            component={ManageDocuments}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/confirm-approval/:id/:requestId"
            component={ConfirmApproval}
            auth={isAuth}
          />
          <SecureRoute
            exact
            path="/confirm-signup/:id/:requestId"
            component={ConfirmSignup}
            auth={isAuth}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute;
