import React from "react";
import { MODE } from "../../../constants/common";
import AddEditContent from "./AddEditContent/AddEditContent";
import ContentList from "./ContentList/ContentList";

function Content(props) {
  const {
    id,
    distributorId,
    contentId,
    mode,
    query,
    onSave,
    onCancel,
    onView,
    onEdit,
    onNext,
    onQueryChange,
    showColumnPopup,
    isMassUploadPermission,
    signUpApproval,
    permissions,
  } = props;

  return (
    <div>
      {mode === MODE.LIST ? (
        <ContentList
          id={id}
          query={query}
          showColumnPopup={showColumnPopup}
          onCancelTableColumnSelection={props.onCancelTableColumnSelection}
          onView={onView}
          onEdit={onEdit}
          setQuery={onQueryChange}
          isMassUploadPermission={isMassUploadPermission}
          signUpApproval={signUpApproval}
          permissions={permissions}
          contentId={contentId}
        />
      ) : (
        <AddEditContent
          id={id}
          distributorId={distributorId}
          contentId={contentId}
          setSelectedSubTab={props.setSelectedSubTab}
          mode={mode}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
          onNext={onNext}
          signUpApproval={signUpApproval}
          permissions={permissions}
        />
      )}
    </div>
  );
}

export default Content;
