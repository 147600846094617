import React, { useEffect, useState } from "react";
import { Modal, Button, Grid, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../../../common/Modal/Modal.css";
import { MandatoryField } from "../../../../common/MandatoryFieldsIcon/MandatoryField";
import ApiServices from "../../../../services/ApiServices";
import {
  ASSOCIATE_SUBSCRIBER,
  MANAGE_SUBSCRIBER_LIST,
  SUBSCRIBER_LIST_ID,
} from "../../../../constants/api-constants";
import { toast } from "react-toastify";
import InlineMessage from "../../../../common/InlineMessage/InlineMessage";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useLoading } from "../../../../hooks/useLoader";
import MultiSelectCheckboxSearchableDropdown from "../../../../common/Modal/MultiSelectCheckboxSearchableDropdown/MultiSelectCheckboxSearchableDropdown";
import { useHistory } from "react-router";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 630,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const AddEditSubscriberModal = (props) => {
  const { bundlepublishStatuss, catalogPublishStatus } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { showLoader } = useLoading();
  const query = {
    searchText: "",
    SortType: "desc",
    SortColumn: "userId",
    IgnorePaging: true,
    Filter: {
      userStatus: [0, 1],
      ContentSubscriberStatus: [0, 1],
    },
  };
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [endDate, setEndDate] = useState("");
  const [subscriberList, setSubscriberList] = useState([]);
  const validStartDate = moment(startDate, "YYYY-MM-DD", true).isValid();
  const validLastDate = moment(endDate, "YYYY-MM-DD", true).isValid();
  const history = useHistory();

  const isValid = () => {
    return (
      selectedValue.length &&
      startDate !== null &&
      startDate !== "" &&
      startDate >= currentDate &&
      validStartDate &&
      (endDate ? startDate <= endDate : true) &&
      (endDate ? validLastDate : true)
    );
  };

  /*istanbul ignore next */
  const associateSubscriber = async (e) => {
    e.preventDefault();
    try {
      let payload = selectedValue?.map((item) => {
        return {
          status: 1,
          contentId: props.contentId,
          subscriberId: item.id,
          associateFrom: moment(startDate).format("YYYY-MM-DD"),
          associateTo:
            (endDate && moment(endDate).format("YYYY-MM-DD")) || null,
          isSubscriberByDist:
            !history.location.pathname.includes("enterprisegroup"),
          userId: props.id,
        };
      });
      const response = await ApiServices.httpPost(
        ASSOCIATE_SUBSCRIBER,
        payload
      );
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        handleClose();
        props.adminDetails();
        setStartDate(moment(new Date()).format("YYYY-MM-DD"));
        setEndDate("");
        setSelectedValue([]);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (err) {}
  };

  useEffect(() => {
    dropDownData();
  }, []);

  const dropDownData = async () => {
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${MANAGE_SUBSCRIBER_LIST}/${props.contentId}/${JSON.stringify(query)}`
      );
      showLoader(false);
      let associatedSubscribers = adminData.result.records
        ? adminData.result.records.map((el) => el.userId)
        : [];
      const data = await ApiServices.httpGet(
        `${SUBSCRIBER_LIST_ID}/${props.id}`
      );
      let isEG = history.location.pathname.includes("enterprisegroup");
      let options =
        data?.result
          ?.filter((x) => isEG === x.isEnterpriseUser)
          ?.map((res) => {
            return {
              label: res.name,
              id: res.userId,
            };
          }) || [];

      let filteredData =
        options?.filter((el) => !associatedSubscribers?.includes(el.id)) || [];

      setSubscriberList(filteredData);
    } catch {
      console.log("error");
    }
  };

  const whenStartDateExeeds =
    startDate < currentDate ? (
      <InlineMessage error="Can't be a past date" />
    ) : null;
  const whenStartDate = !validStartDate ? (
    <InlineMessage error="Invalid Date" />
  ) : (
    whenStartDateExeeds
  );
  const WhenStartDateSmaller = !validLastDate ? (
    <InlineMessage error="Invalid Date" />
  ) : (
    ""
  );
  const whenEndDateNotEmpty =
    startDate > endDate ? (
      <InlineMessage error="Should be greater than or equal to Start date" />
    ) : (
      WhenStartDateSmaller
    );
  return (
    <>
      {bundlepublishStatuss !== 2 && catalogPublishStatus !== 2 && (
        <div className="add-welcome-widgets-breadcrumb-manage-subscriber">
          <Tooltip placement="bottom" title="Associate Subscriber">
            <AddOutlinedIcon
              onClick={() => {
                handleOpen();
                dropDownData();
              }}
              name="add-new-admin"
              role="img"
            />
          </Tooltip>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-modal">
          <form>
            <div className="modal-header">
              <div
                className="modal-header-left"
                data-testid="internal-user-modal"
              >
                Associate Subscriber
              </div>
              <div
                className="modal-header-right"
                onClick={() => {
                  handleClose();
                  setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                  setEndDate(null);
                  setSelectedValue([]);
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseOutlinedIcon />
              </div>
            </div>
            <div className="modal-body manage-subscriber-body">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className="manage-subs-dropdown subscriber-serachable-dropdown"
                >
                  <label htmlFor="searchSubscriber">
                    Search subscriber to associate
                  </label>
                  <MandatoryField />
                  <MultiSelectCheckboxSearchableDropdown
                    width="100%"
                    multiple={true}
                    setData={(value) => {
                      setSelectedValue(value);
                    }}
                    name="searchSubscriber"
                    value={selectedValue}
                    id="searchSubscriber"
                    className="multiple-associate-subscriber-dropdown"
                    options={subscriberList}
                    placeholder="Search and Select"
                    showSelectAllOption={true}
                  />
                  {!selectedValue.length && <InlineMessage error="" />}
                </Grid>
                <Grid item xs={6} className="desktop-date-picker">
                  <label htmlFor="from">Associated From</label>
                  <MandatoryField />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      disablePast
                      className="form-label form-control date-field"
                      inputFormat="MM/DD/YYYY"
                      data-testid="startdate"
                      minDate={moment(new Date()).format("YYYY-MM-DD")}
                      max="9999-12-31"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        e && e._d
                          ? setStartDate(moment(e._d).format("YYYY-MM-DD"))
                          : setStartDate("");
                      }}
                      renderInput={(params) => (
                        <TextField id="from" {...params} />
                      )}
                    />
                  </LocalizationProvider>

                  {!startDate ? <InlineMessage error="" /> : whenStartDate}
                </Grid>
                <Grid item xs={6} className="desktop-date-picker">
                  <label htmlFor="till">Associated Till</label>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      disablePast
                      className="form-label form-control date-field"
                      inputFormat="MM/DD/YYYY"
                      data-testid="enddate"
                      minDate={moment(startDate).format("YYYY-MM-DD")}
                      max="9999-12-31"
                      type="date"
                      value={endDate}
                      onChange={(e) => {
                        e && e._d
                          ? setEndDate(moment(e._d).format("YYYY-MM-DD"))
                          : setEndDate("");
                      }}
                      renderInput={(params) => (
                        <TextField id="till" {...params} />
                      )}
                    />
                  </LocalizationProvider>

                  {endDate === "" ? "" : whenEndDateNotEmpty}
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div className="modal-switch">
                <div className="hide-switch"></div>
              </div>
              <div>
                <Button
                  variant="outlined"
                  className="action-button mr-4"
                  data-testid="close-subscriber-modal"
                  onClick={() => {
                    handleClose();
                    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                    setEndDate("");
                    setSelectedValue([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="add-edit-internal-btn"
                  type="submit"
                  variant="contained"
                  className="action-button"
                  disabled={!isValid()}
                  onClick={associateSubscriber}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
          ;
        </Box>
      </Modal>
    </>
  );
};

export default AddEditSubscriberModal;
