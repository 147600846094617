import { InputAdornment, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Header/Header.css";
import SearchIcon from "@mui/icons-material/Search";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useAuth } from "../../hooks/useAuth";
import { useMasterData } from "../../hooks/useMasterData";
import { useLoading } from "../../hooks/useLoader";
import Popover from "@mui/material/Popover";
import ApiServices from "../../services/ApiServices";
import {
  LOGOUT,
  MODULES_PERMISSION_API,
  MASTER_DATA,
} from "../../constants/api-constants";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { usePageMode } from "../../hooks/usePageMode";
import { fetchImageAsBase64 } from "../../utilities/common";
import { useFormik } from "formik";

const Header = (props) => {
  const [state, setState] = useState({
    bellIcon: false,
    logout: false,
  });
  const formik = useFormik({
    initialValues: {
      role: "0",
      internalRole: "0",
    },
    enableReinitialize: true,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const auth = useAuth();
  const masterData = useMasterData();
  const { showLoader } = useLoading();
  const page = usePageMode();
  const user = auth.getCurrentUser();
  const currentRole = localStorage.getItem("roleName") || "";
  const currentUserId = auth.getCurrentUser()?.email;
  const [image, setImage] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadMasterData = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(MASTER_DATA);
      masterData.setMasterData(response);
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };
  useEffect(() => {
    let res = masterData.masterDataValue;
    if (!res || Object.keys(res).length === 0) {
      loadMasterData();
    }

    if (auth.getBrandImage() !== "null" || !auth.getBrandImage()) {
      setImage(`data:image/jpeg;base64,${auth.getBrandImage()}`);
    }
  }, [auth.getBrandImage()]);

  const profileViewRoute = async () => {
    if (auth.getRole() === 1 || auth.getRole() === 2) {
      history.push(`/users/${auth.getCurrentUser()?.userId}`);
    } else if (auth.getRole() === 3) {
      page.setBreadcrumbRootNode({
        title: props.routeData?.title,
        path: props.routeData?.listRoute,
      });
      history.push(
        "/distributor/" + auth.getCurrentUser()?.userId + "/basic-information"
      );
      page.setEditMode(false);
    } else if (auth.getRole() === 4) {
      page.setBreadcrumbRootNode({
        title: props.routeData?.title,
        path: props.routeData?.listRoute,
      });
      history.push(
        "/enterprisegroup/" +
          auth.getCurrentUser()?.userId +
          "/basic-information"
      );
      page.setEditMode(false);
    }
  };

  const onNext = async (e) => {
    let selectedRole = auth
      .getCurrentUser()
      ?.roles?.filter((el) => el.roleId === Number(e.target.value));
    const intRole =
      formik.values.role === "3" || formik.values.role === "4"
        ? formik.values.internalRole
        : 0;
    auth.setRole(selectedRole[0]);
    auth.setInternalRole(intRole);
    const getPermissions = await ApiServices.httpGet(MODULES_PERMISSION_API);
    if (
      getPermissions.responseStatus.code === 200 &&
      getPermissions.responseStatus.isSuccess
    ) {
      auth.setModules(getPermissions.result.modules);
      if (getPermissions.result.user.logoGcpFileUrl) {
        await fetchImageAsBase64(
          getPermissions.result.user.logoGcpFileUrl
        ).then((data) => {
          auth.setBrandImage(data);
        });
      } else {
        auth.setBrandImage(getPermissions.result.user.logoGcpFileUrl);
      }
    }
    if (history?.location?.state?.from?.pathname) {
      history.push(history.location.state.from.pathname); // return url
    } else {
      history.push("/dashboard"); // Default landing page
    }
    window.location.reload();
  };
  const logout = async () => {
    try {
      const response = await ApiServices.httpPost(LOGOUT, {
        isweb: true,
      });
      if (response.responseStatus?.isSuccess) {
        localStorage.clear();
        toast.success(response.responseStatus?.description);
        // Clear the page content
        document.body.innerHTML = "";
        // Replace the current URL with a new one
        window.history.replaceState(null, null, window.location.href);
        localStorage.setItem(
          "SessionLogout",
          response.responseStatus?.description
        );
        window.location.href = "/login";
      } else {
        toast.success(response.responseStatus?.error);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="notification-profile">
      {/* <div>
            <ToastContainer closeButton={true} position="top-right" />
          </div> */}
      <div className="global-search">
        <TextField
          className="search-text-field"
          type="text"
          placeholder="Search Everything..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div className="search-icon-div">
                  <img src="/images/search-icon.png" alt="search icon" />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <div className="expanded-search">
          <div className="cross-button">
            <CloseOutlinedIcon />
          </div>
          <div className="expanded-left">
            <img src="/images/na.png" alt="Not Avaiable" />
            <div>
              <h6>Couldn’t find a match.</h6>
              <p>Don’t be sad, let’s try again...</p>
            </div>
          </div>
          <div className="expanded-right">
            <div className="people-list">
              <SearchIcon /> <h5>People </h5>
              <ul>
                <li>
                  <Link to="#">Harvey Gill</Link>
                </li>
                <li>
                  <Link to="#">Tomas Crawford</Link>
                </li>
                <li>
                  <Link to="#">Angelina Hubbard</Link>
                </li>
                <li>
                  <Link to="#">Julie Mendez</Link>
                </li>
                <li>
                  <Link to="#">Afolabi David</Link>
                </li>
              </ul>
            </div>
            <div className="role-list">
              <SearchIcon /> <h5>Role </h5>
              <ul>
                <li>
                  <Link to="#">Admin</Link>
                </li>
                <li>
                  <Link to="#">Guest Users</Link>
                </li>
                <li>
                  <Link to="#">XyZ RnR</Link>
                </li>
              </ul>
            </div>
            <div className="tips-list">
              <TipsAndUpdatesOutlinedIcon /> <h5>Quick Tips </h5>
              <div className="tips-data">
                <h5>
                  <span>
                    <img src="/images/command.png" alt="command" />
                  </span>
                  or Ctrl + B
                </h5>
                <h5>
                  Use this keyboard shortcut to instantly search anything.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="notification">
        <div
          className="notification-bell"
          onClick={() => setState({ bellIcon: !state.bellIcon })}
        >
          <img src="/images/bell-svg.svg" alt="bell-icon" name="bell-icon" />
          {state.bellIcon && (
            <div className="notification-bar">
              <div className="notification-bar-header">
                <div className="notification-bar-header-left">
                  <h6>Notification (3)</h6>
                </div>
                <div className="notification-bar-header-right">
                  <h6>Clear All</h6>
                </div>
              </div>
              <div className="notification-bar-main">
                <div>
                  <img src="/images/profile-photo.png" alt="profile" />
                </div>
                <div>
                  <p>kljklsdjfksjfklkjklj lksdfjlksa lkjasdklfjsa</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="logout-menus">
          <div className="user-role-custom">Logged in as {currentRole}</div>
        </div>
        <div className="profile-photo">
          {auth.getBrandImage() !== "null" || !auth.getBrandImage() ? (
            <img
              onClick={handleClick}
              className="profile brandImage"
              src={image}
              alt="brand logo"
              data-testid="brand-logo"
            />
          ) : (
            <div className="profile" onClick={handleClick}>
              {`${user?.firstName[0]?.toUpperCase()}${user?.lastName[0]?.toUpperCase()}`}
            </div>
          )}

          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <div className="logout-menu log-out-popover">
              <ul>
                <div className="logout-menu">
                  <div className="role-info">{currentUserId}</div>
                </div>
                <li>
                  <Link
                    to="#"
                    onClick={async () => {
                      profileViewRoute();
                    }}
                  >
                    <img src="/images/User-1.png" alt="profile" />
                    Profile
                  </Link>
                </li>
                {auth.getCurrentUser()?.roles?.length > 1 && (
                  <li>
                    <Link to="#">
                      <img src="/images/Swith-1.png" alt="profile" />
                      Switch Role
                    </Link>
                    <select
                      onChange={(e) => {
                        onNext(e);
                      }}
                      className="select-user select-cus-roles"
                      aria-label="Default select example"
                      name="role"
                      id="role"
                    >
                      <option key={0} value={0} selected>
                        Select Role
                      </option>
                      {auth.getCurrentUser()?.roles?.map((opt) => {
                        return opt.userTypeId !== auth.getRole() ? (
                          <option key={opt.roleId} value={opt.roleId}>
                            {opt.name}
                          </option>
                        ) : null;
                      })}
                    </select>
                  </li>
                )}
                <li>
                  <span className="custom-logout-css" onClick={logout}>
                    <img src="/images/Logout-1.png" alt="logout" />
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header;
