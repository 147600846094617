import React, { useState } from "react";
import "./SignInWidget.css";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import ApiServices from "../../services/ApiServices";
import { VALIDATE_EMAIL, RESET_PASSWORD } from "../../constants/api-constants";
import { toast } from "react-toastify";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { useHistory } from "react-router";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import OtpTimer from "./OtpTimer";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ForgotPassword = () => {
  const history = useHistory();
  const SCREEN_ENUM = ["email", "reset"];
  const [screen, setScreen] = useState(SCREEN_ENUM[0]);
  const [postSubmitLoading, setPostSubmitLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const emailRegex = /^(?=.{1,100}$)[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!#@$%&])[a-zA-Z\d#!@$%&-]{8,25}$/;
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const handleOtpExpiry = (val) => {
    setIsOtpExpired(val);
  };

  const onValidatingEmail = async () => {
    try {
      setPostSubmitLoading(true);
      let payload = {
        email: username,
      };
      const response = await ApiServices.httpPost(VALIDATE_EMAIL, payload);
      if (response?.result) {
        setPostSubmitLoading(false);
        toast.success(response?.responseStatus?.description);
        setScreen(SCREEN_ENUM[1]);
      } else {
        setUsername("");
        setPostSubmitLoading(false);
        toast.error(response?.responseStatus?.error);
        localStorage.setItem("2FAVerified", false);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const handleIconHover = () => {
    if (!isMobileDevice()) {
      setTooltipOpen(true);
    }
  };

  const handleIconLeave = () => {
    if (!isMobileDevice()) {
      setTooltipOpen(false);
    }
  };

  const handleIconTouch = () => {
    setTooltipOpen(!isTooltipOpen);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 50000);
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  };

  const onResetPassword = async () => {
    try {
      setPostSubmitLoading(true);
      let payload = {
        email: username,
        password: password,
        confirmPassword: confirmPassword,
        otp: otp,
      };
      const response = await ApiServices.httpPost(RESET_PASSWORD, payload);
      if (response?.result) {
        toast.success(response?.responseStatus?.description);
        history.push("/login");
      } else {
        setPostSubmitLoading(false);
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleShowConfirmPassword = () => {
    setConfirmShowPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleOTP = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 6);
    setOtp(sanitizedValue);
  };

  return (
    <div className="myContainer">
      <Container maxWidth="md" className="main-grid-container">
        <Grid container justifyContent="center" className="main-grid">
          <Grid item xs={5} md={5} className="grid-left">
            <img
              src="/images/onivas-logo.png"
              alt="logo"
              className="side-logo logo-login-position"
            />
            <Typography className="login-para" varient="h5" paragraph>
              Software Solutions that Simplify Insurance
            </Typography>
            <div className="cloud-container">
              <img
                className="cloud-container-position"
                src="/images/login-ring.png"
                alt="logo ring"
              />
              <div className="cl1"></div>
              <div className="cl2"></div>
              <div className="cl3"></div>
              <div className="cl4"></div>
              <div className="cl5"></div>
            </div>
          </Grid>

          <Grid item xs={7} md={7} className="login-form">
            <div className="login-form-content">
              <p className="reset-heading">Reset Password</p>
            </div>
            <div
              id="custom-signin"
              class="custom-auth-container"
              style={{ display: "block" }}
            >
              {screen === SCREEN_ENUM[0] && (
                <form onSubmit={onValidatingEmail}>
                  <div class="custom-fieldset">
                    <label htmlFor="username">
                      Username
                      <MandatoryField />
                    </label>
                    <TextField
                      placeholder="Enter username"
                      disabled={postSubmitLoading}
                      id="username"
                      value={username}
                      onChange={(e) => {
                        if (e.target.value.length > 100) {
                          return;
                        }
                        setUsername(e.target.value);
                      }}
                      variant="outlined"
                      data-testid="email"
                    />
                    <InlineMessage
                      error={
                        username && !emailRegex.test(username)
                          ? "Invalid email"
                          : ""
                      }
                    />
                  </div>
                  <div class="custom-fieldset">
                    <Button
                      disabled={
                        !username ||
                        !emailRegex.test(username) ||
                        postSubmitLoading
                      }
                      disableRipple="true"
                      class="sign-in"
                      id="sign-in-id"
                      type="submit"
                      variant="contained"
                      color="primary"
                      data-testid="next-validate"
                      onClick={(e) => {
                        onValidatingEmail();
                      }}
                    >
                      Next
                    </Button>
                  </div>
                  <div class="custom-links">
                    <Link class="clink" to="/login">
                      Back to Login
                    </Link>
                  </div>
                </form>
              )}

              {screen === SCREEN_ENUM[1] && (
                <form onSubmit={onResetPassword}>
                  <div class="custom-fieldset">
                    <label htmlFor="password">
                      New Password
                      <MandatoryField />
                    </label>
                    <TextField
                      autoFocus
                      autoComplete="off"
                      placeholder="Enter new password"
                      disabled={postSubmitLoading}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => {
                        if (e.target.value.length > 25) {
                          return;
                        }
                        setPassword(e.target.value);
                      }}
                      variant="outlined"
                      data-testid="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={postSubmitLoading}
                              onClick={handleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon
                                  data-testid="hidePasswordForgot"
                                  fontSize="small"
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  data-testid="showPasswordForgot"
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <InlineMessage
                      error={
                        password && !passwordRegex.test(password)
                          ? "Invalid Password"
                          : ""
                      }
                    />
                  </div>
                  <div class="custom-fieldset">
                    <label htmlFor="confirmPassword">
                      Confirm Password
                      <MandatoryField />
                    </label>
                    <TextField
                      autoComplete="off"
                      placeholder="Enter confirm password"
                      disabled={postSubmitLoading}
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => {
                        if (e.target.value.length > 25) {
                          return;
                        }
                        setConfirmPassword(e.target.value);
                      }}
                      variant="outlined"
                      data-testid="confirmPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={postSubmitLoading}
                              onClick={handleShowConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOutlinedIcon
                                  data-testid="hideConfirmPasswordForgot"
                                  fontSize="small"
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  data-testid="showConfirmPasswordForgot"
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <InlineMessage
                      error={
                        confirmPassword && confirmPassword !== password
                          ? "Passwords does not match"
                          : ""
                      }
                    />
                  </div>
                  <>
                    <Tooltip
                      placement="bottom-start"
                      title={
                        <div className="custom-icon-info">
                          <p>Your password must contain:-</p>
                          <ul>
                            <li>Minimum 8 characters.</li>
                            <li>At least one number.</li>
                            <li>At least one uppercase character.</li>
                            <li>
                              At least one of the following special characters -
                              "!", "#", "@", "$", "%", "&".
                            </li>
                          </ul>
                        </div>
                      }
                      open={isTooltipOpen}
                      onClose={() => setTooltipOpen(false)}
                      onClick={() => setTooltipOpen(!isTooltipOpen)}
                      onMouseEnter={handleIconHover}
                      onMouseLeave={handleIconLeave}
                      onTouchStart={handleIconTouch}
                      leaveTouchDelay={5000}
                    >
                      <div
                        className="help-text-width"
                        onMouseEnter={handleIconHover}
                        onMouseLeave={handleIconLeave}
                        onTouchStart={handleIconTouch}
                      >
                        <InfoOutlinedIcon className="custom-info-icon help-text-color" />
                        <div className="help-text-cus-position">
                          <span className="help-text-color">Need help?</span>
                        </div>
                      </div>
                    </Tooltip>
                  </>
                  <div class="custom-fieldset">
                    <label htmlFor="otp">
                      One-Time Password
                      <MandatoryField />
                    </label>
                    <TextField
                      type="text"
                      placeholder="Enter One-Time Password"
                      disabled={postSubmitLoading}
                      id="otp"
                      value={otp}
                      onChange={(e) => handleOTP(e)}
                      variant="outlined"
                      maxLength={6}
                    />
                  </div>
                  <div class="custom-fieldset">
                    <Button
                      disabled={
                        isOtpExpired ||
                        !username ||
                        !emailRegex.test(username) ||
                        !passwordRegex.test(password) ||
                        !passwordRegex.test(confirmPassword) ||
                        password !== confirmPassword ||
                        otp.length < 6 ||
                        postSubmitLoading
                      }
                      class="sign-in"
                      id="sign-in-id"
                      type="submit"
                      variant="contained"
                      color="primary"
                      data-testid="reset-password"
                      onClick={(e) => {
                        onResetPassword();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                  <div class="custom-fieldset">
                    <OtpTimer
                      handleOtpExpiry={handleOtpExpiry}
                      postSubmitLoading={postSubmitLoading}
                      resendOTP={onValidatingEmail}
                    />
                  </div>
                </form>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
